<template>
  <router-link
    :class="class_navbar_item"
    :data-test="$_.get(item, 'data_test')"
    style="cursor: pointer; height: 100%"
    :to="navigate_to"
    @click="goTo">
    <p
      class="is-size-6">
      {{ $t(item.title) }}
    </p>
  </router-link>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  name: 'NavbarItem',
  props: {
    is_primary_navbar: { type: Boolean },
    item: { type: Object, required: true },
    params: { type: Object, default: null },
  },
  computed: {
    class_navbar_item() {
      if (this.$route.name.startsWith(this.item.prefix)) {
        return this.is_primary_navbar
          ? 'has-text-weight-bold has-text-white'
          : 'has-text-weight-semibold has-text-primary active'
      }
      return this.is_primary_navbar
        ? 'has-text-primary-light'
        : 'has-text-grey'
    },
    navigate_to() {
      if (this.item.name === 'employeesClientProjects') {
        return {}
      }
      return {
        name: this.item.name,
        params: this.params
      }
    },
  },
  methods: {
    ...mapActions([
      'SET_CURRENT_PROJECT',
    ]),
    goTo() {
      if (this.item.name === 'employeesClientProjects') {
        this.$emit('openProjectSideBar')
      }
    }
  }
};
</script>
