import ButtonDestructive from '@/app/shared_components/buttons/ButtonDestructive.vue';
import ButtonLight from '@/app/shared_components/buttons/ButtonLight.vue';
import ButtonLink from '@/app/shared_components/buttons/ButtonLink.vue';
import ButtonPrimary from '@/app/shared_components/buttons/ButtonPrimary.vue';
import ButtonSecondary from '@/app/shared_components/buttons/ButtonSecondary.vue';
import ButtonTertiary from '@/app/shared_components/buttons/ButtonTertiary.vue';
import ButtonCollapseToggle from '@/app/shared_components/buttons/ButtonCollapseToggle.vue';
import ButtonUpsell from '@/app/shared_components/buttons/ButtonUpsell.vue';

export default {
  install(app) {
    app.component('ButtonDestructive', ButtonDestructive);
    app.component('ButtonLight', ButtonLight);
    app.component('ButtonLink', ButtonLink);
    app.component('ButtonPrimary', ButtonPrimary);
    app.component('ButtonSecondary', ButtonSecondary);
    app.component('ButtonTertiary', ButtonTertiary);
    app.component('ButtonCollapseToggle', ButtonCollapseToggle);
    app.component('ButtonUpsell', ButtonUpsell);
  },
};
