<template>
  <div v-if="current_profile.type !== PROFILE_TYPES.SUPERVISOR" data-test="sidebar-notifications-user">
    <a v-if="!$flipper.enabled($FT.HORIZONTAL_NAV)
         || (current_profile && current_profile.type !== PROFILE_TYPES.EMPLOYEE)"
       class="topbar-item" style="cursor: pointer;" @click="toggleSidebar(true)">
      <span
        class="icon is-large" :class=" (mq.isMobile || mq.isTablet) ? 'has-text-white' : 'has-text-grey'">
        <span
          class="badge has-badge-rounded"
          :class="(mq.isMobile || mq.isTablet) ? 'ziggu-navbar-item' : 'has-text-grey'"
          :data-badge="data_badge"
          data-test="notifications-number">
          <i class="far fa-bell fa-lg"/>
        </span>
      </span>
    </a>
    <div v-else class="w-10 flex justify-center">
      <a @click="toggleSidebar(true)">
        <span
          class="badge has-badge-danger has-badge-rounded has-text-white"
          :data-badge="data_badge"
          data-test="notifications-number">
          <FontAwesomeIcon
            :icon="['far', 'bell']"
            size="lg"/>
        </span>
      </a>
    </div>
    <Sidebar
      id="sidebar-notifications"
      :block-scroll="true"
      class="w-full md:w-[30rem] lg:w-[40rem] mb-0 p-0"
      :dismissable="false"
      position="right"
      :pt="{
        content: 'p-0 pt-0 h-full w-full grow overflow-y-auto',
        mask: 'sidebar_right_vertical_navigation',
        closeButton: { 'data-test': 'sidebar-notifications-close' }
      }"
      :visible="is_visible"
      @update:visible="toggleSidebar(false)">
      <template #header>
        <span class="font-semibold text-2xl">
          {{ $t('notification.title') }}
        </span>
      </template>

      <b-loading v-if="fetch_notifications_at" :is-full-page="false" :model-value="true"/>
      <div class="flex flex-col h-full">
        <NotificationsFilter
          class="shrink-0"
          :sort_order="sort_order"
          :sort_order_type="sort_order_type"
          @fetchNotificationSubgroups="fetchNotificationSubgroups"
          @openNotificationSubgroupsAll="openNotificationSubgroupsAll"
          @setSortOrder="(new_sort_prefs) => { sort_prefs = new_sort_prefs } "/>

        <hr
          v-if="notification_subgroups_page.length === 0"
          class="dropdown-divider is-marginless shrink-0">

        <section class="scrollable shrink-1 grow" data-test="notification-container" style="flex: 1;">
          <div v-if="notification_subgroups_page.length === 0 && !fetch_notifications_at" class="has-text-centered mt-5">
            <p class="subtitle has-text-grey">
              <FontAwesomeIcon v-if="!is_filtering" class="mr-2" :icon="['far', 'thumbs-up']"/>
              {{ $t(is_filtering ? 'notification.labels.noResults' : 'notification.labels.noNewNotification') }}
            </p>
          </div>
          <div v-for="notification_subgroup in notification_subgroups_page" :key="notification_subgroup.id">
            <hr class="dropdown-divider is-marginless">
            <NotificationItem
              :notification_subgroup="notification_subgroup"
              @onChangeReadStatus="fetchNotificationSubgroups"
              @onClick="toggleSidebar(false)"
              @onDelete="deleteNotificationSubgroup(notification_subgroup)"/>
          </div>
        </section>
        <div
          v-show="page_count > 1"
          class="dropdown-item py-4 shrink-0"
          @click.stop>
          <b-pagination
            v-model="sidebar_prefs.page_number"
            :order="pagination.order"
            :per-page="page_size"
            :rounded="false"
            :simple="false"
            :total="notification_subgroup_pages_length"
            @change="page_number => UPDATE_SIDEBAR_PREFS({ page_number })"/>
        </div>
      </div>
    </Sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { NOTIFICATION_SUBGROUPS_PAGE_SIZE } from '@/app/data/pagination_constants';
import { PROFILE_TYPES } from '@/app/data/model_constants';
import NotificationsFilter from '@/app/shared_components/navbar/notifications/NotificationsFilter.vue';
import NotificationItem from '@/app/shared_components/activities/NotificationItem.vue';
import { differenceInSeconds } from 'date-fns';
import { useMqService } from '@/plugins/mq';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const SIDEBAR_TYPE = 'notifications';
const SORT_ORDER_DEFAULT = 'desc'; // Newest first
const SORT_ORDER_TYPE_DEFAULT = 'updated_at';

export default {
  name: 'NotificationsSidebar',
  components: { NotificationsFilter, NotificationItem, FontAwesomeIcon },

  setup() {
    const mq = useMqService();
    return { mq };
  },

  data() {
    return {
      fetch_notifications_at: false,
      page_size: NOTIFICATION_SUBGROUPS_PAGE_SIZE,
      pagination: {
        order: 'is-centered',
      },
      PROFILE_TYPES,
      SIDEBAR_TYPE,
      sort_prefs: [`${SORT_ORDER_TYPE_DEFAULT} ${SORT_ORDER_DEFAULT}`],
    };
  },

  computed: {
    ...mapGetters([
      'current_client',
      'current_profile',
      'customers',
      'employees',
      'notifications_sidebar_last_opened_at',
      'notification_subgroup_pages_length',
      'notification_subgroups',
      'notification_subgroups_page',
      'partners',
      'project',
      'projects',
      'sidebar_prefs',
      'sidebar_type',
      'units',
    ]),

    data_badge() {
      return this.show_notification_point ? '' : null;
    },

    is_filtering() {
      return this.sidebar_prefs.project_id || this.sidebar_prefs.unopen;
    },

    show_notification_point() {
      const notified_at = _.get(this.current_profile, 'attributes.notified_at', (new Date(0)).toISOString());
      return notified_at > this.notifications_sidebar_last_opened_at;
    },

    page_count() {
      return Math.ceil(this.notification_subgroup_pages_length / this.page_size);
    },

    is_visible() {
      return this.sidebar_type === SIDEBAR_TYPE;
    },

    sort_order() {
      return _.last(this.sort_prefs).split(' ')[1];
    },

    sort_order_type() {
      // last property that's sorted on determines the sorting label
      return _.last(this.sort_prefs).split(' ')[0];
    },
  },

  watch: {
    $route() {
      // auto-close sidebar on route change only when it overlaps with content
      if (this.is_visible && !this.mq.isDesktopWide) {
        this.toggleSidebar(false);
      }
    },
    'sidebar_prefs.page_number': {
      handler() {
        this.fetchNotificationSubgroups();
      },
    },
    sort_prefs: {
      handler() {
        this.UPDATE_SIDEBAR_PREFS({ sort_prefs: this.sort_prefs });
        this.fetchNotificationSubgroups();
      },
    },
  },

  created() {
    if (this.sidebar_prefs.sort_prefs) {
      this.sort_prefs = this.sidebar_prefs.sort_prefs;
    } else {
      this.UPDATE_SIDEBAR_PREFS({ sort_prefs: this.sort_prefs });
    }
    this.INIT_NOTIFICATIONS_LAST_OPENED();
    this.emitter.$on('fetchNotificationSubgroups', this.fetchNotificationSubgroups);
    this.fetchNotificationSubgroupsDebounce = _.debounce(this.fetchNotificationSubgroups, 1000);
  },

  methods: {
    ...mapActions([
      'DELETE_NOTIFICATION_SUBGROUP',
      'FETCH_NOTIFICATION_SUBGROUPS_PAGE',
      'INIT_NOTIFICATIONS_LAST_OPENED',
      'OPEN_NOTIFICATION_SUBGROUPS',
      'SHOW_SIDEBAR',
      'UPDATE_NOTIFICATIONS_LAST_OPENED',
      'UPDATE_SIDEBAR_PREFS',
    ]),

    async openNotificationSubgroupsAll() {
      if (_.isEmpty(this.notification_subgroups_page)) return;
      const ids = _.map(this.notification_subgroups_page, 'id');
      await this.OPEN_NOTIFICATION_SUBGROUPS({ ids });
      await this.fetchNotificationSubgroups();
    },

    async deleteNotificationSubgroup(notification_subgroup) {
      this.DELETE_NOTIFICATION_SUBGROUP({ notification_subgroup });
      this.fetchNotificationSubgroupsDebounce();
    },

    toggleSidebar(show) {
      if (this.sidebar_type === SIDEBAR_TYPE) {
        this.SHOW_SIDEBAR(null);
        this.hide_intercom(false);
      } else if (show) {
        if (!this.mq.isDesktop) this.hide_intercom(true);
        this.UPDATE_NOTIFICATIONS_LAST_OPENED();
        this.SHOW_SIDEBAR(SIDEBAR_TYPE);
        this.fetchNotificationSubgroups();
        this.setSidebarMarginTop();

        const debouncedHandler = () => {
          _.debounce(() => {
            this.setSidebarMarginTop();
          }, 100);
        };
        window.addEventListener('resize', debouncedHandler);
        this.emitter.$on('hook:unmounted', () => {
          document.removeEventListener('resize', debouncedHandler);
        });
      }
    },

    hide_intercom(hide) {
      window.Intercom('update', { hide_default_launcher: hide });
    },

    async fetchNotificationSubgroups() {
      if (!this.is_visible) return;
      // locking mechanism to avoid concurrent fetches, with fail-safe auto-release after 60s
      if (differenceInSeconds(this.fetch_notifications_at, new Date()) > 60) this.fetch_notifications_at = null;
      if (this.initialized && this.fetch_notifications_at) return;
      this.fetch_notifications_at = new Date();
      // dynamically determine page size based on current window height
      this.page_size = _.max([Math.floor((window.innerHeight - 235) / 75), 10]);
      await this.FETCH_NOTIFICATION_SUBGROUPS_PAGE({ page_size: this.page_size });
      this.fetch_notifications_at = null;
    },

    setSidebarMarginTop() {
      let margin_top = 0;
      if (document.getElementById('invoice_overdue_banner')) {
        margin_top += document.getElementById('invoice_overdue_banner').offsetHeight;
      }
      if (document.getElementById('ooo_banner')) {
        margin_top += document.getElementById('ooo_banner').offsetHeight;
      }
      if (!this.mq.isMobile && document.getElementById('topbar')) {
        margin_top += document.getElementById('topbar').offsetHeight;
      }
      if (document.getElementById('sidebar-notifications')) {
        const sidebar_content = document.getElementById('sidebar-notifications')
          .getElementsByClassName('sidebar-content')[0];
        sidebar_content.style.marginTop = `${margin_top}px`;
        sidebar_content.style.height = `${window.innerHeight - margin_top}px`;
      }
    },
  },
};
</script>

<style>

[data-badge]::after {
  box-shadow: none !important;
}

</style>
