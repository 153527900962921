/* eslint-disable no-param-reassign,object-curly-newline */
const RECORD_MUTATIONS = {
  // records are first removed based on matching attributes (= API params), afterwards merge is done
  SYNC_RECORDS(state, { records, module, attr, params, namespace }) {
    const attributes = _.pickBy(params, (v, k) => !k.startsWith('_'));
    state[namespace][module][attr] = {
      ..._.omitBy(state[namespace][module][attr], { attributes }), ...records,
    };
  },
  // records are merged, overriding older versions if present
  MERGE_RECORDS(state, { records, module, attr, namespace }) {
    state[namespace][module][attr] = { ...state[namespace][module][attr], ...records };
  },
  SET_RECORDS_PAGE(state, { records_page, module, attr_page, namespace }) {
    state[namespace][module][attr_page] = records_page;
  },
  MERGE_RECORD_PAGE(state, { record, module, attr_page, namespace }) {
    const records_page = state[namespace][module][attr_page];
    const index = _.findIndex(records_page, { id: record.id });
    records_page[index] = record;
  },
  SET_RECORD_PAGES_LENGTH(state, {
    pages_length, pages_unread_length, module, attr_pages_length, attr_pages_unread_length, namespace,
  }) {
    if (_.isNumber(pages_unread_length)) {
      state[namespace][module][attr_pages_unread_length] = pages_unread_length;
    }
    state[namespace][module][attr_pages_length] = pages_length;
  },
  PURGE_RECORD(state, { record, module, attr, namespace }) {
    delete state[namespace][module][attr][record.id];
    state[namespace][module][attr] = { ...state[namespace][module][attr] };

    const attr_page = `${attr}_page`;
    const state_has_attr_page = !_.isEmpty(state[namespace][module][attr_page]);
    if (state_has_attr_page) {
      const index = _.findIndex(state[namespace][module][attr_page], { id: record.id });
      if (index > -1) if (index > -1) state[namespace][module][attr_page].splice(index, 1);
    }
  },
  PURGE_RECORDS(state, { ids, module, attr, namespace }) {
    ids.forEach((record_id) => delete state[namespace][module][attr][record_id]);
    state[namespace][module][attr] = { ...state[namespace][module][attr] };
    const attr_page = `${attr}_page`;
    const state_has_attr_page = !_.isEmpty(state[namespace][module][attr_page]);
    if (state_has_attr_page) {
      ids.forEach((id) => {
        const index = _.findIndex(state[namespace][module][attr_page], { id });
        if (index > -1) state[namespace][module][attr_page].splice(index, 1);
      });
    }
  },
};

export default RECORD_MUTATIONS;
