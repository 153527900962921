import { isEmptyHTML } from '@/app/util/editor/editor';
import { LANGUAGES } from '@/app/data/model_constants';

const getters = {
  translationOrFallbackFor: (_state, { current_client, current_profile }) => (model, field) => {
    if (_.isNil(model) || _.isNil(field)) return '';

    const field_current_locale = model.attributes[field];
    if (!_.isNil(field_current_locale) && !isEmptyHTML(field_current_locale)) {
      return { fallback_translation: field_current_locale };
    }

    const language_client = model.attributes
      .translations[current_client.attributes.language][field];
    if (!_.isNil(language_client) && !isEmptyHTML(language_client)) {
      return { fallback_translation: language_client, fallback_langauge: current_client.attributes.language };
    }

    const remaining_locales = _.filter(
      LANGUAGES,
      (language) => ![current_client.attributes.language, current_profile.attributes.language]
        .includes(language),
    );

    let fallback_translation = null;
    let fallback_langauge = null;
    let index = 0;
    while (_.isNil(fallback_translation) && index < remaining_locales.length) {
      const language = remaining_locales[index];
      const field_translation = _.get(model.attributes.translations, `${language}.${field}`);
      if (!_.isNil(field_translation) && !isEmptyHTML(field_translation)) {
        fallback_translation = field_translation;
        fallback_langauge = language;
      } else {
        index += 1;
      }
    }
    return { fallback_translation, fallback_langauge };
  },
};

export default {
  getters,
};
