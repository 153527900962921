<template>
  <component
    :is="type"
    class="button has-text-weight-semibold is-light is-flex"
    :class="[is_outlined ? 'is-outlined' : '', is_rounded ? 'is-rounded' : '', is_small ? 'is-small' : '']"
    :disabled="is_disabled || null"
    :to="to"
    @click="submit">
    <div v-if="iconFontawesome" class="icon">
      <FontAwesomeIcon :icon="iconFontawesome"/>
    </div>
    <div>
      <slot/>
    </div>
  </component>
</template>

<script>

const actions_to_fontawesome_icon = Object.freeze({
  create: 'plus',
});

export default {
  name: 'ButtonLight',
  props: {
    is_disabled: { type: Boolean },
    is_outlined: { type: Boolean },
    is_small: { type: Boolean },
    to: { type: Object, default: null },
    is_rounded: { type: Boolean },
    action: {
      type: String,
      default: null,
      validator: (prop) => Object.keys(actions_to_fontawesome_icon).includes(prop),
    },
  },

  emits: ['on-click'],

  data() {
    return {
      iconFontawesome: null,
    };
  },

  computed: {
    type() {
      return this.to ? 'router-link' : 'div';
    },
  },

  created() {
    this.iconFontawesome = actions_to_fontawesome_icon[this.action];
  },

  methods: {
    submit() {
      if (this.is_disabled) return;
      this.$emit('onClick');
    },
  },
};
</script>
