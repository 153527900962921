<template>
  <Modal
    data-test="thank-you-modal"
    :has_parent="false"
    :is_cancel="false"
    :is_small="false"
    @actionPrimary="$emit('close')">
    <template #title>
      <div class="flex-center">
        <i class="far fa-circle-check fa-7x has-text-primary"/>
      </div>
    </template>
    <template #content>
      <div class="flex-center">
        <p class="title is-4">
          {{ $t('survey.labels.thankYou') }}
        </p>
        <p class="title is-5">
          {{ $t('survey.labels.clientTeam', { name: client_name }) }}
        </p>
      </div>
    </template>
    <template #ButtonPrimary>
      {{ $t('survey.actions.close') }}
    </template>
  </Modal>
</template>

<script>

import Modal from '@/app/shared_components/modals/TheModal.vue';

export default {
  name: 'ThankYouModal',
  components: { Modal },
  props: {
    client_name: { type: String, required: true },
  },
};
</script>
