<template>
  <div>
    <div class="is-size-5 has-text-grey has-text-weight-medium">
      <div data-test="dashboard-title-activityfeed">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitleSmall',
};
</script>
