import { ROUTES_PARTNERS } from '@/app/data/route_constants';

const routesDecision = [
  {
    name: ROUTES_PARTNERS.PROJECT_DECISION_DOCUMENTS,
    path: 'decision_documents',
    component: () => import('@/app/shared_components/decisions/TheDecisionDocumentsTemplate.vue'),
  },
  {
    name: ROUTES_PARTNERS.PROJECT_DECISION_PROPOSALS,
    path: 'decision_proposals',
    component: () => import('@/app/_partners/subpages/decisions/TheDecisionProposalsSubpage.vue'),
  },
  {
    name: ROUTES_PARTNERS.PROJECT_DECISION_TICKETS,
    path: 'decision_tickets',
    component: () => import('@/app/_partners/subpages/decisions/TheDecisionTicketsSubpage.vue'),
  },
  {
    name: ROUTES_PARTNERS.PROJECT_DECISION_TICKETS_SHOW,
    path: 'decision_ticket/:ticket_id',
    component: () => import('@/app/_partners/subpages/decisions/TheDecisionTicketSubpage.vue'),
    props: true,
  },
  {
    name: ROUTES_PARTNERS.PROJECT_DECISION,
    path: '',
    redirect: { name: ROUTES_PARTNERS.PROJECT_DECISION_PROPOSALS },
  },
];

export default routesDecision;
