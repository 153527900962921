import { ROUTES_EMPLOYEES } from '@/app/data/route_constants';

const routesDecisionType = [
  {
    name: ROUTES_EMPLOYEES.PROJECT_DECISIONS,
    path: 'decisions',
    component: () => import('@/app/_employees/pages/DecisionsPage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_PROPOSAL_TEMPLATES,
    path: 'proposal_templates',
    component: () => import('@/app/_employees/pages/ProposalTemplatePage.vue'),
    props: true,
  },
];

export default routesDecisionType;
