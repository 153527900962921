<template>
  <div data-test="client-settings" success-tag="client-settings">
    <a v-if="!$flipper.enabled($FT.HORIZONTAL_NAV)" class="topbar-item" style="cursor: pointer;" @click="toggle">
      <span
        class="icon is-large" :class=" (mq.isMobile || mq.isTablet) ? 'has-text-white' : 'has-text-grey'">
        <FontAwesomeIcon
          aria-controls="overlay_menu"
          aria-haspopup="true"
          :class="(mq.isMobile || mq.isTablet) ? 'ziggu-navbar-item' : 'has-text-grey'"
          :icon="['far', 'gear']"
          size="lg"/>
      </span>
    </a>
    <a
      v-else
      class="w-10 flex justify-center"
      @click="toggle">
      <FontAwesomeIcon
        aria-controls="overlay_menu"
        aria-haspopup="true"
        class="has-text-white"
        :icon="['far', 'gear']"
        size="lg"/>
    </a>
    <PMenu id="overlay_menu" ref="menu" :model="items" :popup="true">
      <template #item="{ item, props }">
        <div
          v-if="!item.route"
          class="font-bold m-0 py-2 px-5 rounded-tl-none rounded-tr-none
            bg-surface-0 dark:bg-surface-700 text-surface-700 dark:text-white">
          {{ item.label }}
        </div>
        <router-link v-else :to="{ name: item.route }">
          <div v-ripple :data-test="item.datatest" v-bind="props.action">
            <span>{{ $t(`${item.label}`) }}</span>
          </div>
        </router-link>
      </template>
    </PMenu>
  </div>
</template>

<script>

import { PROFILE_TYPES } from '@/app/data/model_constants';
import { ROUTES_EMPLOYEES } from '@/app/data/route_constants';
import { mapGetters } from 'vuex';
import { useMqService } from '@/plugins/mq';

export default {
  name: 'TheClientSettingsNavbar',
  setup() {
    const mq = useMqService();
    return { mq };
  },
  data() {
    return {
      ROUTES_EMPLOYEES,
      PROFILE_TYPES,
    };
  },

  computed: {
    ...mapGetters(['current_profile', 'current_client']),

    items() {
      return [
        { label: this.current_client.attributes.name },
        { label: 'navbar.topnavbar.company', route: ROUTES_EMPLOYEES.CLIENT_COMPANY, datatest: 'admin' },
        { label: 'navbar.topnavbar.team', route: ROUTES_EMPLOYEES.SETTINGS_TEAM, datatest: 'team' },
      ];
    }
  },

  methods: {
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
  }
};

</script>
