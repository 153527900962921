import { ROUTES_CUSTOMERS } from '@/app/data/route_constants';

const routesIssues = [
  {
    component: () => import('@/app/_customers/subpages/issues/AftercareSubpage.vue'),
    path: '',
    props: true,
    name: ROUTES_CUSTOMERS.ISSUES,
  },
  {
    name: ROUTES_CUSTOMERS.ISSUES_DOCUMENTS,
    path: 'documents',
    component: () => import('@/app/_employees/subpages/issue_lists/IssueListDocumentsSubpage.vue'),
    props: true,
  },
  {
    name: ROUTES_CUSTOMERS.ISSUES_TICKETS,
    path: 'tickets',
    component: () => import('@/app/_customers/subpages/issues/AftercareTicketsSubpage.vue'),
    props: true,
  },
  {
    name: ROUTES_CUSTOMERS.ISSUE_NEW_TICKET,
    path: 'issue/:issue_id/tickets/new',
    props: true,
    component: () => import('@/app/_customers/subpages/issues/AftercareNewTicketSubpage.vue'),
  },
];

export default routesIssues;
