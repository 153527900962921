<template>
  <section v-if="banner_message"
           class="hero"
           :class="banner_class"
           style="position: absolute; top: 0; left: 0; right: 0; z-index: 999;">
    <div class="hero-body">
      <div class="container">
        <button
          class="mt-2 delete is-pulled-right"
          @click="closeBanner" />
        <h2 class="title is-4 flex-center" style="line-height: 31px;">
          {{ banner_message }}
        </h2>
      </div>
    </div>
  </section>
</template>

<script>
const defaultState = {
  banner_color: null,
  banner_message: null,
  seconds_left: 5,
};

export default {
  data() {
    return _.cloneDeep(defaultState);
  },
  computed: {
    banner_class() {
      return `is-${this.banner_color}`;
    },
  },
  created() {
    this.emitter.$on('banner', this.showBanner);
  },
  unmounted() {
    this.emitter.$off('banner', this.showBanner);
  },
  methods: {
    closeBanner() {
      Object.assign(this, defaultState);
    },
    showBanner({ message, color }) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.banner_color = color;
      this.banner_message = message;
      this.timeout = setTimeout(() => {
        this.closeBanner();
      }, 5000);
    },
  },
};
</script>

<style lang="scss">

.hero .hero-body {
  padding: 1.7rem;
}
</style>
