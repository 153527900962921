import { getInstance } from './authWrapper';
import { watch } from 'vue';

// eslint-disable-next-line consistent-return
export default function authGuard(to, from, next) {
  const authService = getInstance();

  // eslint-disable-next-line consistent-return
  const fn = () => {
    if (authService.is_authenticated) {
      return next();
    }

    // eslint-disable-next-line consistent-return
    (async () => {
      const token = await authService.getToken();
      if (token) {
        // successful (silent) authentication: redirect to next route
        // -> failed (silent) authentication: getToken() will have already redirected to universal login
        authService.is_authenticated = true;
        return next();
      }
    })();
  };

  if (!authService.loading) return fn();
  watch(() => authService.loading, (loading) => (loading === false ? fn() : false));
}
