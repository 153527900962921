/* eslint-disable no-shadow,no-unused-vars,camelcase,no-param-reassign */
/* eslin-disable no-cycle */
import { createStore } from 'vuex';
import modules from '@/store/shared/index.js';
import RECORD_MUTATIONS from '@/store/lib/mutations';

const store = createStore({
  mutations: {
    ...RECORD_MUTATIONS,
  },

  modules: {
    shared: {
      ...modules
    },
  },
});

export default store;
