import { NOTIFICATIONS_PARTNERS } from '@/app/data/notifications_constants';
import { PROFILE_TYPES, RECORD_TYPE } from '@/app/data/model_constants';
import { mapGetters } from 'vuex';

// mixin used for both activities' notifications and profiles' notifications
const activity_notification_item_mixin = {
  props: {
    activity: { type: Object },
    notification_subgroup: { type: Object },
  },

  computed: {
    ...mapGetters([
      'clients',
      'companies',
      'customers',
      'employees',
      'partners',
      'current_profile',
      'projects',
      'units',
    ]),
    notifier() {
      if (this.notification.attributes.key === NOTIFICATIONS_PARTNERS.UNIT_VISIBLED) {
        return null;
      }

      switch (this.notification.attributes.notifier_type) {
      case PROFILE_TYPES.EMPLOYEE:
        return this.employees[this.notification.attributes.notifier_id];
      case PROFILE_TYPES.CUSTOMER:
        return this.customers[this.notification.attributes.notifier_id];
      case PROFILE_TYPES.PARTNER:
        return this.partners[this.notification.attributes.notifier_id];
      case RECORD_TYPE.CLIENT:
        if (this.current_profile.type === PROFILE_TYPES.CUSTOMER) {
          return this.clients[this.notification.attributes.parameters.client_id];
        }
        return null;
      default:
        return null;
      }
    },

    project_name() {
      return _.get(this.projects[this.notification.attributes.project_id], 'attributes.name');
    },

    params() {
      const client_hash = { client_id: this.$route.params.client_id };
      return { ...client_hash, ...this.notification.attributes.parameters };
    },
  },
};

export default activity_notification_item_mixin;
