<template>
  <div
    v-if="profile.type !== PROFILE_TYPES.EMPLOYEE"
    id="ooo_banner"
    class="p-2 relative
      has-text-weight-medium is-size-6
      is-flex is-align-items-center is-justify-content-center"
    style="height: 100%; width: 100%;">
    <p>{{ client.attributes.ooo_note || $t('client.labels.outOfOffice') }}</p>
  </div>
</template>

<script>

import { ROUTES_EMPLOYEES } from '@/app/data/route_constants';
import { PROFILE_TYPES } from '@/app/data/model_constants';
import { useMqService } from '@/plugins/mq';

export default {
  name: 'TheOutOfOfficeBanner',
  props: {
    client: { type: Object, required: true },
    profile: { type: Object, required: true },
  },

  setup() {
    const mq = useMqService();
    return { mq };
  },

  data() {
    return {
      ROUTES_EMPLOYEES,
      PROFILE_TYPES,
    };
  },
  computed: {
    is_client_settings_page() {
      return this.$route.name === ROUTES_EMPLOYEES.CLIENT_COMPANY;
    },
  },
};
</script>
