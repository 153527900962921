<template>
  <b-tag
    aria-close-label="Close tag"
    class="has-text-grey-dark has-background-grey-lighter"
    :closable="closable"
    rounded
    :size="size"
    type="is-grey-lighter"
    @close.stop="$emit('close')">
    <slot/>
  </b-tag>
</template>

<script>
export default {
  name: 'FilterTag',
  props: {
    closable: { type: Boolean, default: true },
    size: { type: String, default: '' },
  },
};
</script>
