import { ROUTES_EMPLOYEES } from '@/app/data/route_constants';

const routesSurveyType = [
  {
    name: ROUTES_EMPLOYEES.PROJECT_SURVEY_SUMMARY,
    path: 'summary',
    component: () => import('@/app/_employees/pages/SurveySummaryPage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_SURVEYS,
    path: 'surveys',
    component: () => import('@/app/_employees/pages/SurveysPage.vue'),
    props: true,
  },
];

export default routesSurveyType;
