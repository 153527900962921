<template>
  <router-view/>
</template>

<script>
export default {
  name: 'User',

  async created() {
    this.initialized = true;
    this.emitter.$emit('initFavicon');
  },
};
</script>
