import api from '@/api';

class Flipper {
  constructor(store) {
    this.store = store;
    this.API_ENDPOINT = '/app/v1/shared/feature_toggles';
  }

  enabled(feature) {
    const feature_toggles = this.store.getters.feature_toggles;
    return _.flatten(_.valuesIn(feature_toggles)).includes(feature)
  }

  toggle(feature, status, actor) {
    return api.post(this.API_ENDPOINT, {
      actor_type: actor.type,
      actor_id: actor.id,
      feature,
      feature_status: status
    })
  }

  toggles(features, actor) {
    return api.post(this.API_ENDPOINT, {
      actor_type: actor.type,
      actor_id: actor.id,
      features
    })
  }
}

export default {
  install(app, store) {
    app.config.globalProperties.$flipper = new Flipper(store);
  },
};
