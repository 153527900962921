<template>
  <component
    :is="type"
    class="button is-text"
    :to="to"
    @click="$emit('onClick')">
    <div>
      <slot/>
    </div>
    <div v-if="iconFontawesome" class="icon has-text-grey">
      <FontAwesomeIcon :icon="iconFontawesome"/>
    </div>
  </component>
</template>

<script>

const actions_to_fontawesome_icon = Object.freeze({
  other_page: 'forward',
});

export default {
  name: 'ButtonLink',
  props: {
    to: {
      type: Object,
      default: null,
    },
    action: {
      type: String,
      default: null,
      validator: (prop) => Object.keys(actions_to_fontawesome_icon).includes(prop),
    },
  },

  emits: ['on-click'],

  data() {
    return {
      iconFontawesome: null,
    };
  },

  computed: {
    type() {
      return this.to ? 'router-link' : 'button';
    },
  },

  created() {
    this.iconFontawesome = actions_to_fontawesome_icon[this.action];
  },
};
</script>
