import { ROUTES_EMPLOYEES } from '@/app/data/route_constants';

const routesInformation = [
  {
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_INFORMATION,
    path: 'information',
    component: () => import('@/app/_employees/components/levels/TheLevelsInformation.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_CONTACTS,
    path: 'contacts',
    component: () => import('@/app/_employees/components/levels/project/TheProjectContacts.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_TASKS,
    path: 'todos',
    component: () => import('@/app/_employees/components/levels/TheLevelTodos.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_DOCUMENTS,
    path: 'documents',
    component: () => import('@/app/_employees/components/levels/unit/TheUnitDocuments.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_TICKETS,
    path: 'tickets',
    component: () => import('@/app/_employees/components/levels/unit/TheUnitTickets.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_INSTALLMENTS,
    path: 'installments',
    component: () => import('@/app/_employees/components/levels/TheInstallmentsInformation.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_AFTERCARE,
    path: 'aftercare',
    component: () => import('@/app/_employees/components/levels/unit/TheAftercareInformation.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_DECISIONS,
    path: 'decisions',
    component: () => import('@/app/_employees/components/levels/unit/TheUnitDecisions.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_SURVEYS,
    path: 'surveys',
    component: () => import('@/app/_employees/components/levels/unit/TheUnitSurveys.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_ACTIVITY,
    path: 'activity',
    component: () => import('@/app/_employees/components/levels/TheActivityFeed.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_MILESTONES,
    path: 'milestones',
    component: () => import('@/app/_employees/components/levels/project/TheProjectMilestones.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS,
    path: '',
    redirect: { name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_INFORMATION },
  },
];

export default routesInformation;
