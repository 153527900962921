// the notification keys MUST be a 1-1 mapping with the corresponding Ruby file
// WARNING: do _NOT_ rename existing notification keys unless you know what you are doing
export const NOTIFICATIONS_CUSTOMERS = {
  ANNOUNCEMENT_CREATE: 'notification.customers.announcement.create',
  DECISION_DOCUMENT_CREATE: 'notification.customers.decision_document.create',
  DECISION_MESSAGE_CREATE: 'notification.customers.decision.message.create',
  DECISION_PROPOSAL_APPROVED: 'notification.customers.decision_proposal.approved',
  DECISION_PROPOSAL_CREATE: 'notification.customers.decision_proposal.create',
  DECISION_PROPOSAL_EDIT_APPROVED: 'notification.customers.decision_proposal.edit_approved',
  DECISION_PROPOSAL_RELAUNCHED: 'notification.customers.decision_proposal.relaunched',
  DECISION_RELAUNCHED: 'notification.customers.decision.relaunched',
  DECISION_PUBLISHED_CUSTOMER: 'notification.customers.decision.published.customer',
  DOCUMENT_CATEGORY_INVOICE_CREATE: 'notification.customers.document.invoice.create',
  DOCUMENT_CREATE: 'notification.customers.document.create',
  DOCUMENT_PROPOSAL_CREATE: 'notification.customers.document.proposal.create',
  EXPORT_CREATE: 'notification.customers.export.create',
  INSTALLMENT_DOCUMENT_CREATE: 'notification.customers.document.installment.create',
  INSTALLMENT_PUBLISHED_CUSTOMER: 'notification.customers.installment.published',
  INSTALLMENT_STATUS_CHANGED_CUSTOMER: 'notification.customers.installment.status.changed',
  ISSUE_APPROVAL_CREATE: 'notification.customers.issue.approval.create',
  ISSUE_CREATE: 'notification.customers.issue.create',
  ISSUE_MESSAGE_CREATE: 'notification.customers.issue.message.create',
  ISSUE_STATUS_UPDATE: 'notification.customers.issue.status.update',
  QUESTION_CREATE: 'notification.customers.question.create',
  REMINDER_DUE: 'notification.customers.reminder.due',
  SURVEY_PUBLISHED: 'notification.customers.survey.published',
  TICKET_CREATE: 'notification.customers.ticket.create',
  TICKET_DOCUMENT_CREATE: 'notification.customers.ticket_document.create',
  TICKET_MESSAGE_CREATE: 'notification.customers.ticket.message.create',
  TICKET_MESSAGE_MOVE: 'notification.customers.ticket.message.move',
};

export const SUBSCRIPTION_GROUPS_CUSTOMERS = {
  // used in app as i18n keys, for api these are dummy values
  ANNOUNCEMENT: 'subscription.customers.announcement',
  QUESTION: 'subscription.customers.question',
};

export const NOTIFICATIONS_EMPLOYEES = {
  ANNOUNCEMENT_CREATE: 'notification.employees.announcement.create',
  DECISION_DOCUMENT_CREATE: 'notification.employees.decision_document.create',
  DECISION_MESSAGE_CREATE: 'notification.employees.decision.message.create',
  DECISION_PROPOSAL_APPROVED: 'notification.employees.decision_proposal.approved',
  DECISION_PROPOSAL_CREATE: 'notification.employees.decision_proposal.create',
  DECISION_PROPOSAL_REQUESTED_CHANGES: 'notification.employees.decision_proposal.requested_changes',
  DECISION_NOT_INTERESTED: 'notification.employees.decision.not_interested',
  DECISION_PROPOSAL_SIGNED: 'notification.employees.decision_proposal.signed',
  DECISION_RESPONSIBLE_UPDATE: 'notification.employees.decision.responsible.update',
  DOCUMENT_CREATE: 'notification.employees.document.create',
  DOCUMENT_PROPOSAL_CREATE: 'notification.employees.document.proposal.create',
  EXPORT_CREATE: 'notification.employees.export.create',
  EMAIL_CONVERSATION_PROJECT_CREATE: 'notification.employees.email_conversation.project.create',
  EMAIL_CONVERSATION_CLIENT_CREATE: 'notification.employees.email_conversation.client.create',
  ISSUE_APPROVED: 'notification.employees.issue.approved',
  ISSUE_CREATE: 'notification.employees.issue.create',
  ISSUE_MESSAGE_CREATE: 'notification.employees.issue.message.create',
  ISSUE_REJECTED: 'notification.employees.issue.rejected',
  QUESTION_CREATE: 'notification.employees.question.create',
  SURVEY_ANSWER_CREATE: 'notification.employees.survey_answer.create',
  TICKET_CREATE: 'notification.employees.ticket.create',
  TICKET_DOCUMENT_CREATE: 'notification.employees.ticket_document.create',
  TICKET_MENTION_CREATE: 'notification.employees.ticket.mention.create',
  TICKET_MESSAGE_CREATE: 'notification.employees.ticket.message.create',
  TICKET_RESPONSIBLE_UPDATE: 'notification.employees.ticket.responsible.update',
  TODO_ASSIGN: 'notification.employees.todo.assign',
};

export const SUBSCRIPTION_GROUPS_EMPLOYEES = {
  // used in app as i18n keys, for api these are dummy values
  ANNOUNCEMENT: 'subscription.employees.announcement',
  DECISION: 'subscription.employees.decision',
  DOCUMENT: 'subscription.employees.document',
  EXPORT: 'subscription.employees.export',
  QUESTION: 'subscription.employees.question',
  SURVEY: 'subscription.employees.survey',
  TICKET: 'subscription.employees.ticket',
  TODO: 'subscription.employees.todo',
};

export const NOTIFICATIONS_PARTNERS = {
  DECISION_DOCUMENT_CREATE: 'notification.partners.decision_document.create',
  DECISION_MESSAGE_CREATE: 'notification.partners.decision.message.create',
  DECISION_PROPOSAL_APPROVED: 'notification.partners.decision_proposal.approved',
  DECISION_PROPOSAL_CREATE: 'notification.partners.decision_proposal.create',
  DECISION_PROPOSAL_REQUESTED_CHANGES: 'notification.partners.decision_proposal.requested_changes',
  DECISION_NOT_INTERESTED: 'notification.partners.decision.not_interested',
  DECISION_PUBLISHED_PARTNERS: 'notification.partners.decision.published.partners',
  DOCUMENT_PROPOSAL_CREATE: 'notification.partners.document.proposal.create',
  EXPORT_CREATE: 'notification.partners.export.create',
  ISSUE_LIST_PUBLISHED: 'notification.partners.issue_list.published',
  ISSUE_STATUS_UPDATE: 'notification.partners.issue.status.update',
  REMINDER_DUE: 'notification.partners.reminder.due',
  UNIT_VISIBLED: 'notification.partners.unit.visibled',
};

// NOTE: subscription add => data migration _required_ to duplicate subscriptions wrt (un)subscribe(s) related group
export const SUBSCRIPTION_GROUP_TO_SUBSCRIPTION_KEYS = {
  // SUBSCRIPTION_GROUP_NAMES_CUSTOMERS
  [SUBSCRIPTION_GROUPS_CUSTOMERS.ANNOUNCEMENT]: [
    NOTIFICATIONS_CUSTOMERS.ANNOUNCEMENT_CREATE,
  ],
  [SUBSCRIPTION_GROUPS_CUSTOMERS.QUESTION]: [
    NOTIFICATIONS_CUSTOMERS.QUESTION_CREATE,
  ],
  // SUBSCRIPTION_GROUP_NAMES_EMPLOYEES
  [SUBSCRIPTION_GROUPS_EMPLOYEES.ANNOUNCEMENT]: [
    NOTIFICATIONS_EMPLOYEES.ANNOUNCEMENT_CREATE,
  ],
  [SUBSCRIPTION_GROUPS_EMPLOYEES.DECISION]: [
    NOTIFICATIONS_EMPLOYEES.DECISION_DOCUMENT_CREATE,
    NOTIFICATIONS_EMPLOYEES.DECISION_MESSAGE_CREATE,
    NOTIFICATIONS_EMPLOYEES.DECISION_PROPOSAL_APPROVED,
    NOTIFICATIONS_EMPLOYEES.DECISION_PROPOSAL_CREATE,
    NOTIFICATIONS_EMPLOYEES.DECISION_PROPOSAL_SIGNED,
    NOTIFICATIONS_EMPLOYEES.DECISION_PROPOSAL_REQUESTED_CHANGES,
    NOTIFICATIONS_EMPLOYEES.DECISION_NOT_INTERESTED,
    NOTIFICATIONS_EMPLOYEES.DECISION_RESPONSIBLE_UPDATE,
    NOTIFICATIONS_EMPLOYEES.DOCUMENT_PROPOSAL_CREATE,
  ],
  [SUBSCRIPTION_GROUPS_EMPLOYEES.DOCUMENT]: [
    NOTIFICATIONS_EMPLOYEES.DOCUMENT_CREATE,
  ],
  [SUBSCRIPTION_GROUPS_EMPLOYEES.EXPORT]: [
    NOTIFICATIONS_EMPLOYEES.EXPORT_CREATE,
  ],
  [SUBSCRIPTION_GROUPS_EMPLOYEES.QUESTION]: [
    NOTIFICATIONS_EMPLOYEES.QUESTION_CREATE,
  ],
  [SUBSCRIPTION_GROUPS_EMPLOYEES.SURVEY]: [
    NOTIFICATIONS_EMPLOYEES.SURVEY_ANSWER_CREATE,
  ],
  [SUBSCRIPTION_GROUPS_EMPLOYEES.TODO]: [
    NOTIFICATIONS_EMPLOYEES.TODO_ASSIGN,
  ],
  [SUBSCRIPTION_GROUPS_EMPLOYEES.TICKET]: [
    NOTIFICATIONS_EMPLOYEES.TICKET_CREATE,
    NOTIFICATIONS_EMPLOYEES.TICKET_DOCUMENT_CREATE,
    NOTIFICATIONS_EMPLOYEES.TICKET_MESSAGE_CREATE,
    NOTIFICATIONS_EMPLOYEES.TICKET_RESPONSIBLE_UPDATE,
  ],
};

export const SUBSCRIPTION_CHANNELS = Object.freeze({
  PLATFORM: 'platform',
  EMAIL: 'email',
});
