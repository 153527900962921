<template>
  <Modal data-test="survey-into-modal"
         :has_parent="false"
         :is_cancel="false"
         :is_small="false"
         @actionPrimary="$emit('close')">
    <template #title>
      {{ survey_type.attributes.title }}
    </template>
    <template #content>
      <div class="is-size-5 section-small">
        <TextClamp
          v-if="!is_empty_description"
          data-test="survey-description"
          :html_text="survey_type.attributes.description_html"
          :is_background_white="true"
          max_height="250"/>
      </div>
    </template>
    <template #ButtonPrimary>
      {{ $t('survey.labels.lets_go') }}
    </template>
  </Modal>
</template>

<script>

import TextClamp from '@/app/shared_components/text/TextClamp.vue';
import Modal from '@/app/shared_components/modals/TheModal.vue';
import { isEmptyHTML } from '@/app/util/editor/editor';

export default {
  name: 'SurveyIntroModal',
  components: { Modal, TextClamp },
  props: {
    survey_type: { type: Object, required: true },
  },
  computed: {
    is_empty_description() {
      return isEmptyHTML(this.survey_type.attributes.description_html);
    },
  },
};
</script>
