<template>
  <span>
    {{ amount_formatted }}
  </span>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FormatCurrency',

  props: {
    amount: { type: Number, required: false, default: null },
    with_currency_symbol: { type: Boolean, default: true },
    minimum_fraction_digits: { type: Number, default: 0 },
  },

  computed: {
    ...mapGetters(['current_profile', 'current_project']),

    amount_formatted() {
      const {
        amount,
        with_currency_symbol, minimum_fraction_digits,
      } = this;
      const currency = _.get(this.current_project, 'attributes.currency', 'EUR');

      if (_.isNil(amount) || amount === '') return '';

      return this.current_profile.formatCurrency(
        amount,
        {
          currency,
          with_currency_symbol,
          minimumFractionDigits: minimum_fraction_digits,
        },
      );
    },
  },
};
</script>
