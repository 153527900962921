export const DECIMAL_SEPARATOR = Object.freeze({
  POINT: 'point',
  COMMA: 'comma',
});

export const CURRENCY_TO_SYMBOL = Object.freeze({
  AUD: '$',
  DKK: 'Kr.',
  EUR: '€',
  NOK: 'kr',
  GBP: '£',
  SEK: 'kr',
  USD: '$',
  PLN: 'zł',
});

export const CURRENCY_TO_NAME = Object.freeze({
  // AFN: 'Afghani',
  // DZD: 'Algerian Dinar',
  // ARS: 'Argentine Peso',
  // AMD: 'Armenian Dram',
  // AWG: 'Aruban Florin',
  AUD: 'Australian Dollar',
  // AZN: 'Azerbaijan Manat',
  // BSD: 'Bahamian Dollar',
  // BHD: 'Bahraini Dinar',
  // THB: 'Baht',
  // PAB: 'Balboa',
  // BBD: 'Barbados Dollar',
  // BYN: 'Belarusian Ruble',
  // BZD: 'Belize Dollar',
  // BMD: 'Bermudian Dollar',
  // BOB: 'Boliviano',
  // VES: 'Bolívar Soberano',
  // BRL: 'Brazilian Real',
  // BND: 'Brunei Dollar',
  // BGN: 'Bulgarian Lev',
  // BIF: 'Burundi Franc',
  // CVE: 'Cabo Verde Escudo',
  // CAD: 'Canadian Dollar',
  // KYD: 'Cayman Islands Dollar',
  // XOF: 'CFA Franc BCEAO',
  // XAF: 'CFA Franc BEAC',
  // XPF: 'CFP Franc',
  // CLP: 'Chilean Peso',
  // COP: 'Colombian Peso',
  // KMF: 'Comorian Franc ',
  // CDF: 'Congolese Franc',
  // BAM: 'Convertible Mark',
  // NIO: 'Cordoba Oro',
  // CRC: 'Costa Rican Colon',
  // CUP: 'Cuban Peso',
  // CZK: 'Czech Koruna',
  // GMD: 'Dalasi',
  DKK: 'Danish Krone',
  // MKD: 'Denar',
  // DJF: 'Djibouti Franc',
  // STN: 'Dobra',
  // DOP: 'Dominican Peso',
  // VND: 'Dong',
  // XCD: 'East Caribbean Dollar',
  // EGP: 'Egyptian Pound',
  // SVC: 'El Salvador Colon',
  // ETB: 'Ethiopian Birr',
  EUR: 'Euro',
  // FKP: 'Falkland Islands Pound',
  // FJD: 'Fiji Dollar',
  // HUF: 'Forint',
  // GHS: 'Ghana Cedi',
  // GIP: 'Gibraltar Pound',
  // HTG: 'Gourde',
  // PYG: 'Guarani',
  // GNF: 'Guinean Franc',
  // GYD: 'Guyana Dollar',
  // HKD: 'Hong Kong Dollar',
  // UAH: 'Hryvnia',
  // ISK: 'Iceland Krona',
  // INR: 'Indian Rupee',
  // IRR: 'Iranian Rial',
  // IQD: 'Iraqi Dinar',
  // JMD: 'Jamaican Dollar',
  // JOD: 'Jordanian Dinar',
  // KES: 'Kenyan Shilling',
  // PGK: 'Kina',
  // HRK: 'Kuna',
  // KWD: 'Kuwaiti Dinar',
  // AOA: 'Kwanza',
  // MMK: 'Kyat',
  // LAK: 'Lao Kip',
  // GEL: 'Lari',
  // LBP: 'Lebanese Pound',
  // ALL: 'Lek',
  // HNL: 'Lempira',
  // SLL: 'Leone',
  // LRD: 'Liberian Dollar',
  // LYD: 'Libyan Dinar',
  // SZL: 'Lilangeni',
  // LSL: 'Loti',
  // MGA: 'Malagasy Ariary',
  // MWK: 'Malawi Kwacha',
  // MYR: 'Malaysian Ringgit',
  // MUR: 'Mauritius Rupee',
  // MXN: 'Mexican Peso',
  // MXV: 'Mexican Unidad de Inversion (UDI)',
  // MDL: 'Moldovan Leu',
  // MAD: 'Moroccan Dirham',
  // MZN: 'Mozambique Metical',
  // BOV: 'Mvdol',
  // NGN: 'Naira',
  // ERN: 'Nakfa',
  // NAD: 'Namibia Dollar',
  // NPR: 'Nepalese Rupee',
  // ANG: 'Netherlands Antillean Guilder',
  // ILS: 'New Israeli Sheqel',
  // TWD: 'New Taiwan Dollar',
  // NZD: 'New Zealand Dollar',
  // BTN: 'Ngultrum',
  // KPW: 'North Korean Won',
  NOK: 'Norwegian Krone',
  // MRU: 'Ouguiya',
  // PKR: 'Pakistan Rupee',
  // MOP: 'Pataca',
  // TOP: 'Pa’anga',
  // CUC: 'Peso Convertible',
  // UYU: 'Peso Uruguayo',
  // PHP: 'Philippine Peso',
  GBP: 'Pound Sterling',
  // BWP: 'Pula',
  // QAR: 'Qatari Rial',
  // GTQ: 'Quetzal',
  // ZAR: 'Rand',
  // OMR: 'Rial Omani',
  // KHR: 'Riel',
  // RON: 'Romanian Leu',
  // MVR: 'Rufiyaa',
  // IDR: 'Rupiah',
  // RUB: 'Russian Ruble',
  // RWF: 'Rwanda Franc',
  // SHP: 'Saint Helena Pound',
  // SAR: 'Saudi Riyal',
  // XDR: 'SDR (Special Drawing Right)',
  // RSD: 'Serbian Dinar',
  // SCR: 'Seychelles Rupee',
  // SGD: 'Singapore Dollar',
  // PEN: 'Sol',
  // SBD: 'Solomon Islands Dollar',
  // KGS: 'Som',
  // SOS: 'Somali Shilling',
  // TJS: 'Somoni',
  // SSP: 'South Sudanese Pound',
  // LKR: 'Sri Lanka Rupee',
  // XSU: 'Sucre',
  // SDG: 'Sudanese Pound',
  // SRD: 'Surinam Dollar',
  SEK: 'Swedish Krona',
  // CHF: 'Swiss Franc',
  // SYP: 'Syrian Pound',
  // BDT: 'Taka',
  // WST: 'Tala',
  // TZS: 'Tanzanian Shilling',
  // KZT: 'Tenge',
  // TTD: 'Trinidad and Tobago Dollar',
  // MNT: 'Tugrik',
  // TND: 'Tunisian Dinar',
  // TRY: 'Turkish Lira',
  // TMT: 'Turkmenistan New Manat',
  // AED: 'UAE Dirham',
  // UGX: 'Uganda Shilling',
  // CLF: 'Unidad de Fomento',
  // COU: 'Unidad de Valor Real',
  // UYI: 'Uruguay Peso en Unidades Indexadas (UI)',
  // USN: 'US Dollar (Next day)',
  USD: 'US Dollar',
  // UZS: 'Uzbekistan Sum',
  // VUV: 'Vatu',
  // CHE: 'WIR Euro',
  // CHW: 'WIR Franc',
  // KRW: 'Won',
  // YER: 'Yemeni Rial',
  // JPY: 'Yen',
  // CNY: 'Yuan Renminbi',
  // ZMW: 'Zambian Kwacha',
  // ZWL: 'Zimbabwe Dollar',
  PLN: 'Zloty',
});
