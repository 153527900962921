<template>
  <span/>
</template>

<script>
import { ROUTE_COMMON, ROUTES_EMPLOYEES } from '@/app/data/route_constants';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DecisionTypeRedirect',

  beforeRouteEnter(to, from, next) {
    // Click on back button
    if (from.name === ROUTES_EMPLOYEES.PROJECT_DECISION_PROPOSALS) { window.history.go(-1); } else next();
  },

  computed: {
    ...mapGetters([
      'current_profile',
    ]),
  },

  async mounted() {
    try {
      const project_id = this.$route.params.project_id;
      const decision_type_id = this.$route.params.decision_type_id;
      const decisions = await this.FETCH_DECISIONS({ decision_type_id, unit_id: this.$route.params.unit_id });
      const decision_id = _.get(decisions[0], 'id');
      this.$router.push({
        name: ROUTES_EMPLOYEES.PROJECT_DECISION_PROPOSALS,
        params: {
          project_id,
          decision_type_id,
          decision_id,
        },
      });
    } catch (e) {
      // redirect to root on failure and afterwards hard refresh to re-trigger init profile
      this.$router.push({ name: ROUTE_COMMON.ROOT }, () => { window.location.reload(true); });
    }
  },

  methods: {
    ...mapActions([
      'FETCH_DECISIONS',
    ]),
  },

};
</script>
