/* eslint-disable no-shadow,no-param-reassign,camelcase */
import { local_storage, LOCALSTORAGE_OPTIONS, localStorageKey } from '@/store/lib/local_storage';

const state = {
  sidebar_prefs: {
    page_number: 1,
  },
  sidebar_type: null,
};

const actions = {
  INIT_SIDEBAR_PREFS({ commit, getters }) {
    const key = getters.local_storage_key;
    const default_sidebar_prefs = getters.sidebar_prefs;
    if (!key) {
      // e.g. necessary when employee impersonates a customer
      commit('SET_SIDEBAR_PREFS', default_sidebar_prefs);
    } else {
      const sidebar_prefs = { ...default_sidebar_prefs, ...local_storage.get(key) };
      commit('SET_SIDEBAR_PREFS', sidebar_prefs);
      local_storage.set(key, sidebar_prefs);
    }
  },

  UPDATE_SIDEBAR_PREFS({ commit, getters }, sidebar_prefs) {
    const attributes = { ...getters.sidebar_prefs, ...sidebar_prefs };
    commit('SET_SIDEBAR_PREFS', attributes);
    if (getters.local_storage_key) local_storage.set(getters.local_storage_key, sidebar_prefs);
  },

  SHOW_SIDEBAR({ commit }, type) {
    commit('SET_SIDEBAR_TYPE', type);
  },
};

const mutations = {
  SET_SIDEBAR_PREFS(state, sidebar_prefs) {
    state.sidebar_prefs = sidebar_prefs;
  },

  SET_SIDEBAR_TYPE(state, type) {
    state.sidebar_type = type;
  },
};

const getters = {
  sidebar_prefs: (state) => state.sidebar_prefs,
  sidebar_type: (state) => state.sidebar_type,
  local_storage_key: (_state, _getters, rootState) => {
    const { current_profile } = rootState.shared.current;
    return localStorageKey(current_profile, LOCALSTORAGE_OPTIONS.SIDEBAR);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
