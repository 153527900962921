<template>
  <component
    :is="is"
    class="button has-text-weight-semibold has-text-grey"
    :class="size"
    :disabled="(is_disabled || null)"
    style="background-color: transparent;"
    :to="to"
    @click.stop="submit">
    <div v-if="iconFontawesome" class="icon">
      <FontAwesomeIcon :icon="iconFontawesome"/>
    </div>
    <div>
      <slot>{{ $t('button.actions.cancel') }}</slot>
    </div>
    <span v-if="badge_count"
          class="ml-4 mr-2 badge has-badge-rounded"
          :data-badge="badge_count"
          data-test="notifications-number"/>
    <slot name="icon-right"/>
  </component>
</template>

<script>

const ACTIONS_TO_FONTAWESOME_ICON = Object.freeze({
  create: 'plus',
  filter: 'sliders',
  seen_by: ['far', 'eye'],
  shared_with: ['fas', 'users'],
  company: ['fas', 'building'],
  sign_out: 'right-from-bracket',
  upload: 'upload',
});

export default {
  name: 'ButtonTertiary',

  props: {
    badge_count: { type: Number, default: null },
    is_disabled: { type: Boolean },
    action: {
      type: String,
      default: null,
      validator: (prop) => Object.keys(ACTIONS_TO_FONTAWESOME_ICON).includes(prop),
    },
    size: { type: String, default: 'is-normal' },
    to: { type: Object, default: null },
  },

  emits: ['onClick'],

  computed: {
    iconFontawesome() {
      return ACTIONS_TO_FONTAWESOME_ICON[this.action];
    },
    is() {
      return this.to ? 'router-link' : 'button';
    },
  },

  methods: {
    submit() {
      if (this.is_disabled) return;
      this.$emit('onClick');
    },
  },
};
</script>
