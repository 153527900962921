<template>
  <b-button
    :class="[is_toggled ? `${color}`: '']"
    rounded
    @click="$emit('onClick')">
    <slot/>
  </b-button>
</template>

<script>
export default {
  name: 'ToggleToken',
  props: {
    is_toggled: { type: Boolean, default: false },
    color: { type: String, default: 'is-primary' },
  },

  emits: ['on-click'],
};
</script>
