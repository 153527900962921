import { ROUTES_PARTNERS } from '@/app/data/route_constants';

const routesIssueListType = [
  {
    name: ROUTES_PARTNERS.PROJECT_ISSUE_LIST_TYPE_ISSUES,
    path: 'issues',
    component: () => import('@/app/_partners/subpages/issue_lists/IssueListTypeIssuesSubpage.vue'),
    props: true,
  },
  {
    name: ROUTES_PARTNERS.PROJECT_ISSUE_LIST_TYPE_UNITS,
    path: 'units',
    component: () => import('@/app/_partners/subpages/issue_lists/IssueListTypeUnitsSubpage.vue'),
    props: true,
  },
];

export default routesIssueListType;
