import breadcrumbs from './Breadcrumbs.vue';
import { ref } from 'vue';

class BreadCrumbs {
  crumbs = ref([]);

  add(arr) {
    this.crumbs.value = arr;
  }

  append(arr) {
    arr.forEach((crumb) => this.crumbs.value.push(crumb));
  }

  reset() {
    this.crumbs.value = [];
  }
}

export default {
  install(app) {
    app.config.globalProperties.$breadcrumbs = new BreadCrumbs();
    app.component('Breadcrumbs', breadcrumbs);
  },
};
