// constants of environment variables (found in .env.%environment% files in app root)
export const VITE_ENV_STAGING = 'staging';
export const VITE_ENV_PRODUCTION = 'production';
export const VITE_ENV_DEVELOPMENT = 'development';
export const VITE_ENV_REVIEW = 'review';
export const VITE_ENVS = [
  VITE_ENV_STAGING,
  VITE_ENV_PRODUCTION,
  VITE_ENV_DEVELOPMENT,
  VITE_ENV_REVIEW,
];

export const LOGIN_PAGE_NOTIFICATIONS = Object.freeze({
  ERROR_SESSION_EXPIRED: 'error_session_expired',
  ERROR_NO_ACCESS: 'error_no_access',
  SUCCESS_LOGOUT: 'success_logout',
});

// Used to open helpdesk in the correct language (other than English).
// English is the fallback and content folder exists in the root folder help/docs/.
// Other languages have content folders in a dedicated locale folder f.e. help/docs/nl.
export const HELPDESK_SUPPORTED_LOCALES = ['nl', 'fr'];

export const HELPDESK_SUPPORTED_PROFILE_TYPES = ['Employee', 'Partner'];

export const MAX_FILE_SIZE_IMAGE = '5000000'; // 5MB
