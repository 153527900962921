<template>
  <router-link
    class="ziggu-navbar-item-desktop"
    :class="$route.name.startsWith(item.prefix) ? 'has-background-primary has-text-weight-semibold active' : ''"
    :data-test="$_.get(item, 'data_test')"
    style="cursor: pointer;"
    :to="{ name: item.name, params }">
    <div class="icon mr-2 is-large">
      <FontAwesomeIcon :icon="item.icon" size="lg"/>
    </div>
    <p
      class="is-size-5">
      {{ $t(item.title) }}
    </p>
  </router-link>
</template>

<script>
export default {
  name: 'NavbarItem',
  props: {
    item: { type: Object, required: true },
    params: { type: Object, default: () => ({}) },
  },
};
</script>
