import { ROUTES_EMPLOYEES } from '@/app/data/route_constants';

const routesIssueListType = [
  {
    name: ROUTES_EMPLOYEES.PROJECT_ISSUE_LIST_TYPE_ISSUES,
    path: 'issues',
    component: () => import('@/app/_employees/subpages/issue_lists/IssueListTypeIssuesSubpage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_ISSUE_LIST_TYPE_TICKETS,
    path: 'tickets',
    props: true,
    component: () => import('@/app/_employees/subpages/issue_lists/IssueListTicketsSubpage.vue'),
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_ISSUE_LIST_TYPE_UNITS,
    path: 'units',
    component: () => import('@/app/_employees/subpages/issue_lists/IssueListTypeUnitsSubpage.vue'),
    props: true,
  },
];

export default routesIssueListType;
