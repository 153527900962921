<template>
  <div class="has-text-grey hover-primary"
       :disabled="(is_disabled || null)"
       @click="submit">
    <a>
      <div v-if="is_collapse">
        <FontAwesomeIcon class="mr-1" :icon="row_collapsed ? 'plus' : 'minus'"/>
        {{ row_collapsed ? button_text.expand : button_text.collapse }}
      </div>
      <div v-else>
        <slot/>
      </div>
    </a>
  </div>
</template>

<script>

export default {
  name: 'ButtonCollapseToggle',
  props: {
    button_text: { type: Object, default: () => {} },
    is_disabled: { type: Boolean },
    is_collapse: { type: Boolean },
    row_collapsed: { type: Boolean },
  },

  emits: ['on-click'],

  methods: {
    submit() {
      if (this.is_disabled) return;
      this.$emit('onClick');
    },
  },
};
</script>
