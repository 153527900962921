import { mapGetters } from 'vuex';

const can_open_implicitly_mixin = {
  computed: {
    ...mapGetters([
      'current_is_impersonation',
      'current_is_demo',
    ]),

    can_open_implicitly() {
      return !this.current_is_impersonation || this.current_is_demo;
    },
  }
}

export default can_open_implicitly_mixin;
