// the activity keys MUST be a 1-1 mapping with the corresponding Ruby file
//   if a corresponding profile notification exists, it's recommended that the suffix and constant naming is equal
//     this enables tracking reads of correspoonding notifications and visualizing these for other profile types
import { ROUTES_EMPLOYEES } from '@/app/data/route_constants';

export const ACTIVITIES_PROJECTS = {
  ANNOUNCEMENT_CREATE: 'activity.projects.announcement.create',
  BUILDING_CREATE: 'activity.projects.building.create',
  DOCUMENT_CREATE: 'activity.projects.document.create',
  LOT_CREATE: 'activity.projects.lot.create',
  PHASE_CREATE: 'activity.projects.phase.create',
  QUESTION_CREATE: 'activity.projects.question.create',
  SPACE_CREATE: 'activity.projects.space.create',
  SPACE_UPDATE: 'activity.projects.space.update',
  SURVEY_TYPE_CREATE: 'activity.projects.survey_type.create',
  TICKET_CREATE: 'activity.projects.ticket.create',
  UNIT_ASSIGN: 'activity.projects.unit.assign',
  UNIT_CREATE: 'activity.projects.unit.create',
  UNIT_INVISIBLED: 'activity.projects.unit.invisibled',
  UNIT_UNASSIGN: 'activity.projects.unit.unassign',
  UNIT_VISIBLED: 'activity.projects.unit.visibled',
};

export const ACTIVITIES_UNITS = {
  ANNOUNCEMENT_CREATE: 'activity.units.announcement.create',
  DECISION_CREATE: 'activity.units.decision.create',
  DECISION_DOCUMENT_CREATE: 'activity.units.decision_document.create',
  DECISION_PROPOSAL_APPROVED: 'activity.units.decision_proposal.approved',
  DECISION_PROPOSAL_CREATE: 'activity.units.decision_proposal.create',
  DECISION_PROPOSAL_UNAPPROVED: 'activity.units.decision_proposal.unapproved',
  DECISION_PUBLISHED_CUSTOMER: 'activity.units.decision.published.customer',
  DECISION_PUBLISHED_PARTNERS: 'activity.units.decision.published.partners',
  DECISION_RELAUNCHED: 'activity.units.decision.relaunched',
  DECISION_NOT_INTERESTED: 'activity.units.decision.not_interested',
  DOCUMENT_CREATE: 'activity.units.document.create',
  ISSUE_APPROVAL_CREATE: 'activity.units.issue.approval.create',
  ISSUE_APPROVAL_DELETE: 'activity.units.issue.approval.delete',
  ISSUE_APPROVED: 'activity.units.issue.approved',
  ISSUE_CREATE: 'activity.units.issue.create',
  ISSUE_REJECTED: 'activity.units.issue.approval.rejected',
  ISSUE_STATUS_UPDATE: 'activity.units.issue.status.update',
  PROPOSAL_DELETED: 'activity.units.proposal.deleted',
  QUESTION_CREATE: 'activity.units.question.create',
  SPACE_UPDATE: 'activity.units.space.update',
  SURVEY_CREATE: 'activity.units.survey.create',
  SURVEY_PUBLISHED: 'activity.units.survey.published',
  TICKET_CREATE: 'activity.units.ticket.create',
  TICKET_MESSAGE_MOVE: 'activity.units.ticket.message.move',
  UNIT_ASSIGN: 'activity.units.unit.assign',
  UNIT_INVISIBLED: 'activity.units.unit.invisibled',
  UNIT_UNASSIGN: 'activity.units.unit.unassign',
  UNIT_VISIBLED: 'activity.units.unit.visibled',
};

export const ACTIVITIES_WITH_SEEN_BY = new Set([
  ACTIVITIES_PROJECTS.ANNOUNCEMENT_CREATE,
  ACTIVITIES_PROJECTS.DOCUMENT_CREATE,
  ACTIVITIES_PROJECTS.QUESTION_CREATE,
  ACTIVITIES_PROJECTS.TICKET_CREATE,
  ACTIVITIES_UNITS.ANNOUNCEMENT_CREATE,
  ACTIVITIES_UNITS.DECISION_DOCUMENT_CREATE,
  ACTIVITIES_UNITS.DECISION_PROPOSAL_APPROVED,
  ACTIVITIES_UNITS.DECISION_PROPOSAL_CREATE,
  ACTIVITIES_UNITS.DECISION_PUBLISHED_CUSTOMER,
  ACTIVITIES_UNITS.DOCUMENT_CREATE,
  ACTIVITIES_UNITS.QUESTION_CREATE,
  ACTIVITIES_UNITS.TICKET_CREATE,
]);

export const ACTIVITIES_PROJECTS_TO_ROUTES = {
  [ACTIVITIES_PROJECTS.ANNOUNCEMENT_CREATE]: ROUTES_EMPLOYEES.PROJECT_ANNOUNCEMENTS,
  [ACTIVITIES_PROJECTS.BUILDING_CREATE]: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_INFORMATION,
  [ACTIVITIES_PROJECTS.DOCUMENT_CREATE]: ROUTES_EMPLOYEES.PROJECT_DOCUMENTS,
  [ACTIVITIES_PROJECTS.LOT_CREATE]: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_INFORMATION,
  [ACTIVITIES_PROJECTS.PHASE_CREATE]: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_INFORMATION,
  [ACTIVITIES_PROJECTS.QUESTION_CREATE]: ROUTES_EMPLOYEES.PROJECT_QUESTION_LISTS_SHOW,
  [ACTIVITIES_PROJECTS.SPACE_CREATE]: ROUTES_EMPLOYEES.PROJECT_INFORMATION_SPACES,
  [ACTIVITIES_PROJECTS.SPACE_UPDATE]: ROUTES_EMPLOYEES.PROJECT_INFORMATION_SPACES,
  [ACTIVITIES_PROJECTS.SURVEY_TYPE_CREATE]: ROUTES_EMPLOYEES.PROJECT_SURVEYS,
  [ACTIVITIES_PROJECTS.TICKET_CREATE]: ROUTES_EMPLOYEES.PROJECT_TICKETS_SHOW,
  [ACTIVITIES_PROJECTS.UNIT_ASSIGN]: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_INFORMATION,
  [ACTIVITIES_PROJECTS.UNIT_CREATE]: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_INFORMATION,
  [ACTIVITIES_PROJECTS.UNIT_INVISIBLED]: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_INFORMATION,
  [ACTIVITIES_PROJECTS.UNIT_UNASSIGN]: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_INFORMATION,
  [ACTIVITIES_PROJECTS.UNIT_VISIBLED]: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_INFORMATION,
};

export const ACTIVITIES_UNITS_TO_ROUTES = {
  [ACTIVITIES_UNITS.ANNOUNCEMENT_CREATE]: ROUTES_EMPLOYEES.PROJECT_ANNOUNCEMENTS,
  [ACTIVITIES_UNITS.DECISION_CREATE]: ROUTES_EMPLOYEES.PROJECT_DECISION_TICKETS,
  [ACTIVITIES_UNITS.DECISION_DOCUMENT_CREATE]: ROUTES_EMPLOYEES.PROJECT_DECISION_DOCUMENTS,
  [ACTIVITIES_UNITS.DECISION_PROPOSAL_APPROVED]: ROUTES_EMPLOYEES.PROJECT_DECISION_PROPOSALS,
  [ACTIVITIES_UNITS.DECISION_PROPOSAL_CREATE]: ROUTES_EMPLOYEES.PROJECT_DECISION_PROPOSALS,
  [ACTIVITIES_UNITS.DECISION_PROPOSAL_UNAPPROVED]: ROUTES_EMPLOYEES.PROJECT_DECISION_PROPOSALS,
  [ACTIVITIES_UNITS.DECISION_PUBLISHED_CUSTOMER]: ROUTES_EMPLOYEES.PROJECT_DECISION_TICKETS,
  [ACTIVITIES_UNITS.DECISION_PUBLISHED_PARTNERS]: ROUTES_EMPLOYEES.PROJECT_DECISION_TICKETS,
  [ACTIVITIES_UNITS.DECISION_RELAUNCHED]: ROUTES_EMPLOYEES.PROJECT_DECISION_PROPOSALS,
  [ACTIVITIES_UNITS.DECISION_NOT_INTERESTED]: ROUTES_EMPLOYEES.PROJECT_DECISION_PROPOSALS,
  [ACTIVITIES_UNITS.DOCUMENT_CREATE]: ROUTES_EMPLOYEES.PROJECT_DOCUMENTS,
  [ACTIVITIES_UNITS.ISSUE_APPROVAL_CREATE]: ROUTES_EMPLOYEES.PROJECT_ISSUE_UNIT_ISSUES,
  [ACTIVITIES_UNITS.ISSUE_APPROVAL_DELETE]: ROUTES_EMPLOYEES.PROJECT_ISSUE_UNIT_ISSUES,
  [ACTIVITIES_UNITS.ISSUE_APPROVED]: ROUTES_EMPLOYEES.PROJECT_ISSUE_UNIT_ISSUES,
  [ACTIVITIES_UNITS.ISSUE_REJECTED]: ROUTES_EMPLOYEES.PROJECT_ISSUE_UNIT_ISSUES,
  [ACTIVITIES_UNITS.ISSUE_STATUS_UPDATE]: ROUTES_EMPLOYEES.PROJECT_ISSUE_UNIT_ISSUES,
  [ACTIVITIES_UNITS.PROPOSAL_DELETED]: ROUTES_EMPLOYEES.PROJECT_DECISION_PROPOSALS,
  [ACTIVITIES_UNITS.QUESTION_CREATE]: ROUTES_EMPLOYEES.PROJECT_QUESTION_LISTS_SHOW,
  [ACTIVITIES_UNITS.SPACE_UPDATE]: ROUTES_EMPLOYEES.PROJECT_INFORMATION_SPACES,
  [ACTIVITIES_UNITS.SURVEY_CREATE]: ROUTES_EMPLOYEES.PROJECT_SURVEYS,
  [ACTIVITIES_UNITS.SURVEY_PUBLISHED]: ROUTES_EMPLOYEES.PROJECT_SURVEYS,
  [ACTIVITIES_UNITS.TICKET_CREATE]: ROUTES_EMPLOYEES.PROJECT_TICKETS_SHOW,
  [ACTIVITIES_UNITS.TICKET_MESSAGE_MOVE]: ROUTES_EMPLOYEES.PROJECT_TICKETS_SHOW,
  [ACTIVITIES_PROJECTS.UNIT_ASSIGN]: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_INFORMATION,
  [ACTIVITIES_PROJECTS.UNIT_CREATE]: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_INFORMATION,
  [ACTIVITIES_PROJECTS.UNIT_INVISIBLED]: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_INFORMATION,
  [ACTIVITIES_PROJECTS.UNIT_UNASSIGN]: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_INFORMATION,
  [ACTIVITIES_PROJECTS.UNIT_VISIBLED]: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_INFORMATION,
};

export const ACTIVITIES_TO_ROUTES = {
  ...ACTIVITIES_PROJECTS_TO_ROUTES,
  ...ACTIVITIES_UNITS_TO_ROUTES,
};

export const ACTIVITIES_ICONS = [
  {
    notifiable_type: 'Document',
    iconClass: 'fas fa-file-lines fa-lg',
  },
  {
    notifiable_type: 'Ticket',
    iconClass: 'fas fa-comment fa-lg',
  },
  {
    notifiable_type: 'Todo',
    iconClass: 'far fa-square-check fa-lg',
  },
  {
    notifiable_type: 'DecisionType',
    iconClass: 'far fa-house-circle-check fa-lg',
  },
  {
    notifiable_type: 'Decision',
    iconClass: 'far fa-house-circle-check fa-lg',
  },
  {
    notifiable_type: 'DecisionDocument',
    iconClass: 'far fa-house-circle-check fa-lg',
  },
  {
    notifiable_type: 'DecisionProposal',
    iconClass: 'far fa-house-circle-check fa-lg',
  },
  {
    notifiable_type: 'Proposal',
    iconClass: 'far fa-house-circle-check fa-lg',
  },
  {
    notifiable_type: 'Question',
    iconClass: 'fas fa-circle-question fa-lg',
  },
  {
    notifiable_type: 'Announcement',
    iconClass: 'fas fa-bullhorn fa-lg',
  },
  {
    notifiable_type: 'AnnouncementLevel',
    iconClass: 'fas fa-bullhorn fa-lg',
  },
  {
    notifiable_type: 'Message',
    iconClass: 'fas fa-comment fa-lg',
  },
  {
    notifiable_type: 'Unit',
    iconClass: 'fas fa-comment fa-lg',
  },
  {
    notifiable_type: 'Building',
    iconClass: 'fas fa-comment fa-lg',
  },
  {
    notifiable_type: 'Lot',
    iconClass: 'fas fa-comment fa-lg',
  },
  {
    notifiable_type: 'Phase',
    iconClass: 'fas fa-comment fa-lg',
  },
  {
    notifiable_type: 'Project',
    iconClass: 'fas fa-comment fa-lg',
  },
  {
    notifiable_type: 'Space',
    iconClass: 'fas fa-warehouse fa-lg',
  },
  {
    notifiable_type: 'Survey',
    iconClass: 'fas fa-poll fa-lg',
  },
  {
    notifiable_type: 'SurveyType',
    iconClass: 'fas fa-poll fa-lg',
  },
  {
    notifiable_type: 'Reminder',
    iconClass: 'far fa-bell fa-lg',
  },
  {
    notifiable_type: 'Customer',
    iconClass: 'fas fa-users fa-lg',
  },
  {
    notifiable_type: 'Employee',
    iconClass: 'fas fa-users fa-lg',
  },
  {
    notifiable_type: 'Export',
    iconClass: 'fas fa-download fa-lg',
  },
  {
    notifiable_type: 'Default',
    iconClass: 'far fa-bell fa-lg',
  },
  {
    notifiable_type: 'Issue',
    iconClass: 'far fa-house-chimney-heart fa-lg',
  }
];
