<template>
  <div
    v-if="initialized"
    id="app"
    :class="{
      'ziggu_sm': mq.isMobile || mq.isTablet, 'ziggu_lg': mq.isDesktop ||mq.isDesktopWide ,
      'environment-staging-border': environment === VITE_ENV_STAGING,
      'environment-development-border': environment === VITE_ENV_DEVELOPMENT,
      'environment-review-border': environment === VITE_ENV_REVIEW
    }"
  >
    <NavbarDesktopSupervisor v-if="mq.isDesktop || mq.isDesktopWide"/>
    <NavbarTabletSupervisor v-else/>
    <TopbarDesktop v-if="mq.isDesktop || mq.isDesktopWide"/>
    <TopbarTablet v-else/>
    <main id="page" :class="{'sidebar-padding': mq.isDesktopWide && sidebar_type }">
      <div id="container" :class="{ 'section container is-widescreen': !is_dashboard_page }">
        <router-view/>
      </div>
    </main>
  </div>
</template>

<script>
import NavbarDesktopSupervisor from '@/app/_supervisors/components/navbar/TheNavbarDesktopSupervisor.vue';
import NavbarTabletSupervisor from '@/app/_supervisors/components/navbar/TheNavbarTabletSupervisor.vue';
import TopbarDesktop from '@/app/shared_components/navbar/TheTopbarDesktop.vue';
import { ROUTES_SUPERVISORS } from '@/app/data/route_constants';
import profile_mixin from '@/app/util/profile_mixin';
import TopbarTablet from '../shared_components/navbar/TheTopbarTablet.vue';
import { useMqService } from '@/plugins/mq';

export default {
  name: 'Supervisor',
  components: {
    NavbarDesktopSupervisor,
    NavbarTabletSupervisor,
    TopbarDesktop,
    TopbarTablet,
  },
  mixins: [profile_mixin],

  setup() {
    const mq = useMqService();
    return { mq };
  },

  data() {
    return {
      ROUTES_SUPERVISORS,
    };
  },

  computed: {
    is_dashboard_page() {
      return this.$route.name.startsWith(ROUTES_SUPERVISORS.DASHBOARD);
    },
  },

  async created() {
    await this.initProfile();
  },
};

</script>
