<template>
  <div v-if="!$flipper.enabled($FT.HORIZONTAL_NAV)">
    <router-link
      v-if="current_profile.type === PROFILE_TYPES.EMPLOYEE || current_profile.type === PROFILE_TYPES.PARTNER"
      :to="{ name: routeMarketplace }">
      <a
        class="topbar-item"
        :class="{ 'has-background-primary': is_marketplace_active }"
        data-test="marketplace"
        style="cursor: pointer;">
        <span
          class="icon" :class=" (mq.isMobile || mq.isTablet) ? 'has-text-white' : 'has-text-grey'">
          <span
            class="badge has-badge-rounded"
            :class="is_marketplace_active ? 'has-text-white' : 'has-text-grey'">
            <FontAwesomeIcon :icon="['far', 'store']" size="lg"/>
          </span>
        </span>
      </a>
    </router-link>
  </div>
  <div v-else class="w-10 flex justify-center">
    <router-link
      v-if="current_profile.type === PROFILE_TYPES.EMPLOYEE || current_profile.type === PROFILE_TYPES.PARTNER"
      :to="{ name: routeMarketplace }">
      <FontAwesomeIcon class="has-text-white" :icon="['far', 'store']" size="lg"/>
    </router-link>
  </div>
</template>

<script>

import { PROFILE_TYPES } from '@/app/data/model_constants';
import { mapGetters } from 'vuex';
import { ROUTES_EMPLOYEES, ROUTES_PARTNERS } from '@/app/data/route_constants';
import { useMqService } from '@/plugins/mq';

export default {
  name: 'TheMarketplaceNavbar',
  setup() {
    const mq = useMqService();
    return { mq };
  },
  data() {
    return {
      PROFILE_TYPES,
    };
  },

  computed: {
    ...mapGetters(['current_profile', 'current_client']),

    is_marketplace_active() {
      return this.$route.name.startsWith(ROUTES_EMPLOYEES.CLIENT_MARKETPLACE)
        || this.$route.name.startsWith(ROUTES_PARTNERS.CLIENT_MARKETPLACE);
    },
    routeMarketplace() {
      if (this.current_profile.type === PROFILE_TYPES.EMPLOYEE) return ROUTES_EMPLOYEES.CLIENT_MARKETPLACE;
      return ROUTES_PARTNERS.CLIENT_MARKETPLACE
    }
  }
};

</script>
