import { ROUTES_EMPLOYEES } from '@/app/data/route_constants';
import routesProject from '@/router/employee/routes-project';
import routesEmployeeOverviews from '@/router/employee/routes-employee-overviews';

// Pay attention: when changing routes and params, be extra careful and check with backend notifiable params
// Route params and notifiable params for matched for notification routing

const employeeRoutes = [
  {
    name: ROUTES_EMPLOYEES.CLIENT_CONTACTS,
    path: 'contacts',
    component: () => import('@/app/_employees/pages/ContactsPage.vue'),
    props: true,
    children: [
      {
        name: ROUTES_EMPLOYEES.CLIENT_CONTACTS_TYPE,
        path: ':profile_type/:profile_id(\\d+)?',
        component: () => import('@/app/_employees/pages/ContactTypePage.vue'),
        props: true,
      },
      {
        name: ROUTES_EMPLOYEES.CLIENT_CONTACTS_NEW,
        path: ':profile_type/new',
        component: () => import('@/app/_employees/pages/ContactNewPage.vue'),
        props: true,
      },
    ],
  },
  {
    name: ROUTES_EMPLOYEES.CLIENT_DASHBOARD,
    path: 'dashboard',
    component: () => import('@/app/_employees/pages/DashboardPage.vue'),
  },
  {
    name: ROUTES_EMPLOYEES.CLIENT_PROJECTS,
    path: 'projects',
    component: () => import('@/app/_employees/pages/ProjectsPage.vue'),
  },
  {
    name: ROUTES_EMPLOYEES.CLIENT_PROJECT_NEW,
    path: 'projects/new',
    component: () => import('@/app/_employees/pages/ProjectNewPage.vue'),
  },
  {
    path: 'project/:project_id/:level_type?/:level_id?',
    component: () => import('@/app/shared_components/Project.vue'),
    props: true,
    children: routesProject,
  },
  {
    name: ROUTES_EMPLOYEES.CLIENT_EMAIL_CONVERSATIONS,
    path: 'inbound_emails',
    component: () => import('@/app/_employees/pages/EmailConversationsPage.vue'),
  },
  {
    name: ROUTES_EMPLOYEES.CLIENT_EMAIL_CONVERSATIONS_SHOW,
    path: 'inbound_emails/:email_conversation_id',
    component: () => import('@/app/_employees/pages/EmailConversationPage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.CLIENT_TICKETS_NEW,
    path: 'tickets/new',
    component: () => import('@/app/_employees/pages/TicketNewPage.vue'),
  },
  {
    name: ROUTES_EMPLOYEES.CLIENT_TICKETS_SHOW,
    path: 'ticket/:ticket_id',
    component: () => import('@/app/_employees/pages/TicketPage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.CLIENT,
    path: 'client',
    component: () => import('@/app/_employees/pages/ClientPage.vue'),
    props: true,
    children: [
      {
        name: ROUTES_EMPLOYEES.CLIENT_COMPANY,
        path: 'company',
        component: () => import('@/app/_employees/pages/CompanyPage.vue'),
        props: true,
      },
      {
        name: ROUTES_EMPLOYEES.CLIENT_INTEGRATIONS,
        path: 'integrations',
        component: () => import('@/app/_employees/pages/ClientIntegrationsPage.vue'),
        props: true,
      },
      {
        name: ROUTES_EMPLOYEES.CLIENT_SETTINGS,
        path: 'settings',
        component: () => import('@/app/_employees/pages/SettingsClientPage.vue'),
        props: true,
      },
      {
        name: ROUTES_EMPLOYEES.CLIENT_SUBSCRIPTION,
        path: 'subscription',
        component: () => import('@/app/_employees/pages/SubscriptionPage.vue'),
        props: true,
      },
    ],
  },
  {
    name: ROUTES_EMPLOYEES.SETTINGS_PROFILE,
    path: 'settings/profile',
    component: () => import('@/app/_employees/pages/SettingsEmployeePage.vue'),
  },
  {
    name: ROUTES_EMPLOYEES.SETTINGS_TEAM,
    path: 'settings/team',
    component: () => import('@/app/_employees/pages/SettingsTeamPage.vue'),
  },
  {
    name: ROUTES_EMPLOYEES.CLIENT_MARKETPLACE,
    path: 'marketplace',
    component: () => import('@/app/_employees/pages/MarketplacePage.vue'),
  },
  ...routesEmployeeOverviews,
];

export default employeeRoutes;
