/* eslint-disable no-shadow,no-param-reassign,camelcase */
import api from '@/api';
import normalise from '@/app/util/normalise';
import { Employee, Tenant, PROFILE_TYPES } from '@/app/data/model_constants';

import { DECIMAL_SEPARATOR } from '@/app/data/currencies_constants';

import { HELPDESK_SUPPORTED_LOCALES, HELPDESK_SUPPORTED_PROFILE_TYPES } from '@/app/data/general_constants';

const URL_BASE_PROFILE = '/app/v1/profile/';
const URL_BASE_PROFILES = '/app/v1/profiles/';

const state = {
  current_attachment_categories: [],
  current_client: null,
  current_help_center_base_url: '',
  current_level: null,
  current_profile: null,
  current_profile_true: null,
  current_project: null,
  current_tenant: null,
  current_user: null,
  current_user_clients: {},
  current_user_customers: {},
  current_user_employees: {},
  current_user_partners: {},
  current_user_supervisors: {},
  current_user_tenants: {},
  namespace: null,
};

const actions = {
  ASSIGN_CURRENT_PROFILE() {
    // Empty action to prevents throwing error that action is not recognized.
    // Happens during app load when profile is fetched but store module is not started yet.
  },

  FETCH_USER_PROFILE: async ({ commit, dispatch }) => {
    const r = await api.get(`${URL_BASE_PROFILE}`);
    const json = normalise(r);
    const current_user = Object.values(json.User)[0];
    let current_profile_true;
    // current_profile_true is the highest in rank profile found in the response
    if (json.Supervisor) {
      [current_profile_true] = Object.values(json.Supervisor);
    } else if (json.Employee) {
      [current_profile_true] = Object.values(json.Employee);
    } else if (json.Partner) {
      [current_profile_true] = Object.values(json.Partner);
    } else if (json.Customer) {
      [current_profile_true] = Object.values(json.Customer);
    }
    let current_profile;
    // current_profile is the lowest in rank profile found in the response (reverse order)
    if (json.Customer) {
      [current_profile] = Object.values(json.Customer);
    } else if (json.Partner) {
      [current_profile] = Object.values(json.Partner);
    } else if (json.Employee) {
      [current_profile] = Object.values(json.Employee);
    } else if (json.Supervisor) {
      [current_profile] = Object.values(json.Supervisor);
    }

    if (json.Tenant) {
      const [current_tenant] = Object.values(json.Tenant);
      commit('SET_CURRENT_TENANT', current_tenant);
    }

    commit('SET_CURRENT_USER', current_user);
    if (current_profile) {
      commit('SET_CURRENT_PROFILE_TRUE', current_profile_true);
      commit('SET_CURRENT_PROFILE', current_profile);
      dispatch('ASSIGN_CURRENT_PROFILE', { profile: current_profile });
      dispatch('INIT_SIDEBAR_PREFS');
    }
  },

  SET_CURRENT_HELPCENTER_URL: ({ commit }, { hostname }) => {
    commit('SET_CURRENT_HELPCENTER_URL', hostname);
  },

  FETCH_USER_PROFILES: async ({ commit }) => {
    const r = await api.get(URL_BASE_PROFILES);
    const json = normalise(r);

    const current_user_customers = json.Customer || {};
    const current_user_employees = json.Employee || {};
    const current_user_supervisors = json.Supervisor || {};
    const current_user_partners = json.Partner || {};
    const current_user_tenants = json.Tenant || {};
    const current_user_projects = json.Project || {};
    const current_user_clients = json.Client || {};

    commit('SET_CURRENT_USER_CUSTOMERS', current_user_customers);
    commit('SET_CURRENT_USER_EMPLOYEES', current_user_employees);
    commit('SET_CURRENT_USER_PARTNERS', current_user_partners);
    commit('SET_CURRENT_USER_SUPERVISORS', current_user_supervisors);
    commit('SET_CURRENT_USER_TENANTS', current_user_tenants);
    commit('SET_CURRENT_USER_CLIENTS', current_user_clients);
    commit('SET_CURRENT_USER_PROJECTS', current_user_projects);
  },

  UPDATE_EMAIL_ADDRESS_RESET: async (_, { email_address_new, email_address_reset_token }) => {
    await api.get('/app/v1/shared/email_address_resets/', {
      params: { email_address_new, email_address_reset_token },
    });
  },

  PASSWORD_RESET: async (_, { subdomain, email_address }) => {
    await api.get(`/auth/password_resets?subdomain=${subdomain}&email_address=${email_address}`);
  },

  SET_CURRENT_PROJECT: async ({ commit }, { project }) => {
    commit('SET_CURRENT_PROJECT', project);
  },

  SET_CURRENT_LEVEL: async ({ commit }, { level }) => {
    commit('SET_CURRENT_LEVEL', level)
  },

  LOGOUT_USER: async () => {
    await api.patch('/app/v1/users/logout');
  },
};

const mutations = {
  SET_CURRENT_USER: (state, current_user) => {
    state.current_user = current_user;
  },

  SET_CURRENT_USER_CUSTOMERS: (state, current_user_customers) => {
    state.current_user_customers = current_user_customers;
  },

  SET_CURRENT_USER_EMPLOYEES: (state, current_user_employees) => {
    state.current_user_employees = current_user_employees;
  },

  SET_CURRENT_USER_PARTNERS: (state, current_user_partners) => {
    state.current_user_partners = current_user_partners;
  },

  SET_CURRENT_USER_SUPERVISORS: (state, current_user_supervisors) => {
    state.current_user_supervisors = current_user_supervisors;
  },

  SET_CURRENT_USER_CLIENTS: (state, current_user_clients) => {
    state.current_user_clients = current_user_clients;
  },

  SET_CURRENT_USER_PROJECTS: (state, current_user_projects) => {
    state.current_user_projects = current_user_projects;
  },

  SET_CURRENT_USER_TENANTS: (state, current_user_tenants) => {
    state.current_user_tenants = current_user_tenants;
  },

  SET_CURRENT_PROFILE: (state, current_profile) => {
    state.current_profile = current_profile;
    state.namespace = `${current_profile.type.toLowerCase()}s`;
  },

  SET_CURRENT_PROJECT: (state, current_project) => {
    state.current_project = _.cloneDeep(current_project);
    if (_.isNil(current_project)) return;

    const client = state.current_client;
    if (client && client.id === state.current_project.attributes.client_id && client.attributes.rundle_start_date) {
      const rundle_start_date = _.get(client, 'attributes.rundle_start_date', null);
      const project_created_at = _.get(state.current_project, 'attributes.created_at', null);

      if (_.isNil(project_created_at)) state.current_project.attributes.rundle = true;
      else {
        state.current_project.attributes.rundle = (!_.isNil(rundle_start_date)
          && new Date(rundle_start_date) < new Date(project_created_at));
      }
    }
  },

  SET_CURRENT_LEVEL: (state, current_level) => {
    state.current_level = current_level;
  },

  SET_CURRENT_PROFILE_TRUE: (state, current_profile_true) => {
    state.current_profile_true = current_profile_true;
  },

  SET_CURRENT_TENANT: (state, current_tenant) => {
    state.current_tenant = current_tenant;
  },

  SET_CURRENT_HELPCENTER_URL: (state, hostname) => {
    const current_profile = state.current_profile;

    if (!current_profile) return;
    // no suffix means it defaults to english
    const local_suffix = HELPDESK_SUPPORTED_LOCALES.includes(current_profile.attributes.language)
      ? `/${current_profile.attributes.language}`
      : '';
    let help_port = '';
    let help_profile_type = '';
    switch (current_profile.type.toLowerCase()) {
    case 'employee':
      help_port = import.meta.env.VITE_HELP_EMPLOYEE_PORT;
      break;
    case 'partner':
      help_port = import.meta.env.VITE_HELP_PARTNER_PORT;
      break;
    default:
      help_port = import.meta.env.VITE_HELP_EMPLOYEE_PORT;
    }
    help_profile_type = HELPDESK_SUPPORTED_PROFILE_TYPES.includes(current_profile.type)
      ? current_profile.type.toLowerCase()
      : 'employee';
    state.current_help_center_base_url = help_port
      ? `https://${hostname}:${help_port}/help/${help_profile_type}${local_suffix}`
      : `https://${hostname}/help/${help_profile_type}${local_suffix}`;
  },
};

const getters = {
  current_profile: (state) => {
    const profile = state.current_profile;
    const project = state.current_project;

    // TODO: this is not appropriate, should be refactored as a separate helper function, getters should be kept simple
    if (!_.isNil(profile)) {
      profile.formatCurrency = (
        amount,
        {
          with_currency_symbol = true,
          minimumFractionDigits = 0,
          maximumFractionDigits = 2,
        } = {},
      ) => {
        const { decimal_separator } = profile.attributes;
        const locale = (decimal_separator === DECIMAL_SEPARATOR.COMMA || _.isEmpty(decimal_separator)) ? 'nl' : 'en';
        const currency = _.get(project, 'attributes.currency', 'EUR');

        if (amount % 1 !== 0) {
          const decimalPart = amount.toString().split('.')[1];
          if (decimalPart && decimalPart !== '0') {
            const length = decimalPart.length;
            minimumFractionDigits = length > 2 ? 2 : length;
          }
        } else {
          minimumFractionDigits = 0
        }

        return Intl.NumberFormat(
          locale,
          {
            style: with_currency_symbol ? 'currency' : 'decimal',
            currency,
            minimumFractionDigits,
            maximumFractionDigits,
          },
        ).format(amount);
      };
    }

    return profile;
  },
  current_profile_true: (state) => state.current_profile_true,
  current_project: (state) => state.current_project,
  current_level: (state) => state.current_level,
  current_integrity: (state) => {
    const default_date = (new Date()).toISOString();
    const updated_at_client = _.get(state, 'current_client.attributes.updated_at', default_date);
    const updated_at_profile = _.get(state, 'current_profile.attributes.updated_at', default_date);
    const notified_at_profile = _.get(state, 'current_profile.attributes.notified_at', default_date);
    const logout_at_user = _.get(state, 'current_user.attributes.logout_at', default_date);
    const updated_ts_client = Math.floor(Date.parse(updated_at_client) / 1000);
    const updated_ts_profile = Math.floor(Date.parse(updated_at_profile) / 1000);
    const notified_ts_profile = Math.floor(Date.parse(notified_at_profile) / 1000);
    const logout_ts_user = Math.floor(Date.parse(logout_at_user) / 1000);
    return `client.updated_at=${updated_ts_client},`
      + `profile.updated_at=${updated_ts_profile},`
      + `profile.notified_at=${notified_ts_profile},`
      + `user.logout_at=${logout_ts_user}`;
  },
  current_tenant: (state) => state.current_tenant,
  current_help_center_base_url: (state) => state.current_help_center_base_url,
  current_user_customers: (state) => state.current_user_customers,
  current_user_employees: (state) => state.current_user_employees,
  current_user_partners: (state) => state.current_user_partners,
  current_user_supervisors: (state) => state.current_user_supervisors,
  current_user_profiles: (state) => _.concat([],
    _.values(state.current_user_customers),
    _.values(state.current_user_employees),
    _.values(state.current_user_partners),
    _.values(state.current_user_supervisors)),
  current_user_clients: (state) => state.current_user_clients,
  current_user_projects: (state) => state.current_user_projects,
  current_user_tenants: (state) => state.current_user_tenants,
  namespace: (state) => state.namespace,
  current_client: (state) => state.current_client,
  current_attachment_categories: (state) => state.current_attachment_categories,
  current_user: (state) => state.current_user,
  current_is_impersonation: (state) => {
    if (state.current_user && state.current_profile) {
      return state.current_user.id !== state.current_profile.attributes.user_id;
    }
    return false;
  },
  current_is_integration: (state) => {
    if (state.current_user && state.current_profile) {
      // only employee profiles representing an integration are allowed to (currently) edit integration fields
      //  these by convention haven an email address based on format "%subdomain%@ziggu.app"
      return state.current_profile.type === PROFILE_TYPES.EMPLOYEE
        && _.endsWith(state.current_profile.attributes.email_address, '@ziggu.app');
    }
    return false;
  },
  current_is_demo: (state) => state.current_client.attributes.status === Tenant.STATUS_DEMO,
  current_profile_is_employee_owner: (state) => state.current_user
      && state.current_profile
      && state.current_profile.type === PROFILE_TYPES.EMPLOYEE
      && state.current_profile.attributes.role === Employee.ROLE_OWNER,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
