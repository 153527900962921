<template>
  <aside id="sidenavbar_tenant">
    <div class="sidenavbar_up section">
      <div class="flex-center">
        <img src="@/assets/images/ziggu_logo_supervisor.png" style="height: 95px;">
      </div>
    </div>
    <div class="sidenavbar_down">
      <ul>
        <NavbarItem
          v-for="(item, idx) in NAVBAR_ITEMS_SUPERVISORS"
          :key="idx"
          :item="item"/>
      </ul>
      <div class="is-flex is-justify-content-center p-2" style="width: 100%;">
        <a
          href="https://ziggu.io"
          style="width: 60px;"
          target="_blank">
          <img src="../../../../assets/images/ziggu_logo.svg" style="opacity: 0.5;">
        </a>
      </div>
    </div>
  </aside>
</template>

<script>
import NavbarItem from '@/app/shared_components/navbar/NavbarItem.vue';
import { NAVBAR_ITEMS_SUPERVISORS } from '@/app/data/navbar_constants';

export default {
  name: 'NavbarDesktopSupervisor',
  components: {
    NavbarItem,
  },

  data() {
    return {
      NAVBAR_ITEMS_SUPERVISORS,
    };
  },
};
</script>
