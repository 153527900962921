<template>
  <div
    id="invoice_overdue_banner"
    class="p-4
      has-text-weight-medium is-size-4
      is-flex is-align-items-center is-justify-content-center"
    style="height: 80px; width: 100%; position: relative">
    <p>{{ billing.attributes.invoice_overdue_note || $t('client.labels.invoice_overdue') }}</p>
    <b-modal :can-cancel="[]" :model-value="invoice_overdue_big_show">
      <Modal
        :has_parent="false"
        :is_cancel="false"
        :is_small="false"
        @actionPrimary="hideModal">
        <template #title>
          {{ $t('client.labels.invoice_overdue_title') }}
        </template>
        <template #content>
          <p>{{ billing.attributes.invoice_overdue_note || $t('client.labels.invoice_overdue') }}</p>
        </template>
        <template #ButtonPrimary>
          Ok!
        </template>
      </Modal>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'InvoiceOverdueBanner',

  props: {
    billing: { type: Object, required: true },
  },

  data() {
    return {
      invoice_overdue_big_show: false,
    };
  },

  created() {
    const hidden = sessionStorage.getItem('invoice_overdue_big_hidden');

    if (this.billing.attributes.invoice_overdue_urgent && (_.isNil(hidden) || !hidden)) {
      this.invoice_overdue_big_show = true;
    }
  },

  methods: {
    hideModal() {
      this.invoice_overdue_big_show = false;
      sessionStorage.setItem('invoice_overdue_big_hidden', true);
    },
  },
};
</script>
