import { ROUTES_EMPLOYEE_OVERVIEWS } from '@/app/data/route_constants';

const overviewRoutes = [
  {
    name: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_TASKS,
    path: 'overview/tasks',
    component: () => import('@/app/_employees/pages/overview/OverviewTasksPage.vue')
  },
  {
    component: () => import('@/app/_employees/pages/overview/OverviewMilestonesPage.vue'),
    path: 'overview/milestones',
    children: [
      {
        name: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_MILESTONES,
        path: '',
        component: () => import('@/app/_employees/components/overview/OverviewMilestoneList.vue'),
      },
      {
        name: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_UNIT_MILESTONES,
        path: 'units',
        component: () => import('@/app/_employees/components/overview/OverviewMilestoneUnitList.vue'),
      }
    ]
  },
  {
    name: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_AFTERCARE,
    path: 'overview/aftercare',
    component: () => import('@/app/_employees/pages/overview/OverviewAftercarePage.vue'),
  },
  {
    name: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_CONVERSATIONS,
    path: 'overview/conversations',
    component: () => import('@/app/_employees/pages/overview/OverviewConversationsPage.vue'),
  },
  {
    name: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_DECISIONS,
    path: 'overview/decisions',
    component: () => import('@/app/_employees/pages/overview/OverviewDecisionsPage.vue'),
  },
  {
    name: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_DOCUMENTS,
    path: 'overview/documents',
    component: () => import('@/app/_employees/pages/overview/OverviewDocumentsPage.vue'),
  },
  {
    name: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_FAQS,
    path: 'overview/faqs',
    component: () => import('@/app/_employees/pages/overview/OverviewFAQsPage.vue'),
  },
  {
    name: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_FINANCIALS,
    path: 'overview/financials',
    component: () => import('@/app/_employees/pages/overview/OverviewFinancialsPage.vue'),
  },
  {
    name: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_NEWS,
    path: 'overview/news',
    component: () => import('@/app/_employees/pages/overview/OverviewNewsPage.vue'),
  },
  {
    name: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_SURVEYS,
    path: 'overview/surveys',
    component: () => import('@/app/_employees/pages/overview/OverviewSurveysPage.vue'),
  },

];

export default overviewRoutes;
