<template>
  <nav
    v-if="current_project"
    id="secondarybar"
    class="topbar is-flex is-align-items-center"
    data-test="navbar-desktop-topbar"
    style="height: 40px;">
    <a
      class="has-text-weight-semibold is-size-5 is-flex is-align-items-center has-background-white px-4"
      style="height: 100%"
      @click="show_project_sidebar = true">
      <span v-if="current_project.attributes.skip_customer"
            class="template-icon-container-large mr-2">
        <FontAwesomeIcon
          class="has-text-grey"
          :icon="['far', 'memo']"
          size="md"/>
      </span>
      <Avatar
        v-else
        class="mr-2"
        size="30"
        :src="current_project.attributes.picture_login_url_s"
        :username="current_project.attributes.name"/>
      {{ $truncate(current_project.attributes.name, 50) }}
    </a>

    <div class="topbar-divider"/>

    <ul v-if="mq.isDesktop" class="is-flex is-align-items-center ml-2">
      <NavbarItemHorizontal
        v-for="(item, idx) in routesActive"
        :key="idx"
        class="mr-5"
        :item="item"
        :params="params"
      />
    </ul>

    <div v-else>
      <div class="p-2 cursor-pointer hover:text-gray-500" @click="toggle">
        <FontAwesomeIcon
          :icon="['far','bars']"
          size="lg"/>
      </div>

      <PMenu id="overlay_menu" ref="menu" :model="routesActive" :popup="true">
        <template #item="{ item, props }">
          <NavbarItemHorizontal
            v-bind="props.action"
            :item="item"
            :params="params"
          />
        </template>
      </PMenu>
    </div>

    <TheProjectSideBar
      :show_project_sidebar="show_project_sidebar"
      @closeProjectSidebar="show_project_sidebar = false"/>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import { useMqService } from '@/plugins/mq';
import { NAVBAR_ITEMS_EMPLOYEES_SECONDARY_NAVBAR } from '@/app/data/navbar_constants';
import NavbarItemHorizontal from '@/app/shared_components/navbar/NavbarItemHorizontal.vue';
import TheProjectSideBar from '@/app/_employees/components/sidebars/TheProjectSideBar.vue';

export default {
  name: 'TheNavbarDesktopClientHorizontalSecondary',
  components: {
    NavbarItemHorizontal,
    TheProjectSideBar
  },

  setup() {
    const mq = useMqService();
    return { mq };
  },

  data() {
    return {
      show_project_sidebar: false
    };
  },

  computed: {
    ...mapGetters(['current_client', 'current_project']),
    routesActive() {
      return _.filter(NAVBAR_ITEMS_EMPLOYEES_SECONDARY_NAVBAR, this.isRouteActive);
    },
    level() {
      return this.current_project.attributes.skip_unit ? this.current_level : this.current_project;
    },
    params() {
      return {
        project_id: this.current_project.id,
        level_type: _.get(this.level, 'type'),
        level_id: _.get(this.level, 'id')
      };
    }
  },

  methods: {
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    isRouteActive(route) {
      if (this.current_project.attributes.skip_customer) {
        return !route.hidden_skip_customer;
      } if (route.flipper) {
        return this.$flipper.enabled(route.flipper);
      }
      return true;
    },
  }
};
</script>
