let offset_vertical = null;

const scrollable_mixin = {
  data() {
    return {
      element_page: null,
    };
  },

  mounted() {
    if (!this.$options.preserveScrollPosition) return;

    this.element_page = document.getElementById('page');
    if (!this.element_page) return;

    if (offset_vertical) {
      this.$nextTick(this.scrollToPreviousPosition);
    } else {
      this.element_page.scrollTop = 0;
    }

    this.is_initialized_watcher = this.$watch('is_initialized', async (new_value) => {
      if (new_value) this.$nextTick(this.scrollToPreviousPosition);
    });
  },

  beforeRouteLeave(to, from, next) {
    if (this.$options.preserveScrollPosition) offset_vertical = this.element_page.scrollTop;
    if (this.is_initialized_watcher) this.is_initialized_watcher();
    next();
  },

  methods: {
    scrollToPreviousPosition() {
      this.element_page.scrollTop = offset_vertical;
      if (this.element_page.scrollTop === offset_vertical) offset_vertical = null;
    },
  },
};

export default {
  install(app) {
    app.mixin(scrollable_mixin);
  },
};
