export const ACTIVITIES_PAGE_SIZE = 20;
export const ANNOUNCEMENTS_PAGE_SIZE = 15;
export const CUSTOMERS_PAGE_SIZE = 40;
export const LEADS_PAGE_SIZE = 40;
export const PROJECTS_PAGE_SIZE = 40;
export const EMPLOYEES_PAGE_SIZE = 40;
export const PARTNERS_PAGE_SIZE = 40;
export const DECISION_TYPE_MATRIX_SIZE = 50;
export const DECISIONS_PAGE_SIZE = 30;
export const INSTALLMENTS_PAGE_SIZE = 30;
export const DOCUMENTS_PAGE_SIZE = 20;

export const MILESTONES_LISTS_PAGE_SIZE = 20;
export const NOTIFICATION_SUBGROUPS_PAGE_SIZE = 11;
export const SURVEYS_PAGE_SIZE = 30;
export const TICKETS_PAGE_SIZE = 30;
export const TODOS_PAGE_SIZE = 15;
export const TODO_LISTS_PAGE_SIZE = 10;
export const ASSIGNMENTS_PAGE_SIZE = 10;
export const EMAIL_EVENTS_PAGE_SIZE = 100;
export const SESSIONS_PAGE_SIZE = 100;
export const ISSUES_PAGE_SIZE = 30;
export const UNITS_PAGE_SIZE = 30;
export const QUESTIONS_PAGE_SIZE = 30;
export const QUESTION_LISTS_PAGE_SIZE = 30;
