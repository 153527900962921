<template>
  <div>
    <div v-if="question_number && questions && questions > 1" class="is-italic">
      {{ $t('survey.labels.question_number', { current_number: question_number, total_questions: questions }) }}
    </div>
    <div class="is-flex is-centered mb-5">
      <div class="is-flex-grow-1">
        <h4 class="title is-4 has-text-grey-dark">
          {{ title_survey }}
        </h4>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column">
        <Rating v-if="question_type === SurveyQuestion.NPS || question_type === SurveyQuestion.OPEN_ENDED"
                :default_value="score"
                :disabled="disabled"
                :question_type="question_type"
                @scoreSelected="scoreSelected"/>
        <YesNo v-if="question_type === SurveyQuestion.YES_NO"
               :default_value="score"
               :disabled="disabled"
               @scoreSelected="scoreSelected"/>
        <Satisfaction v-if="question_type === SurveyQuestion.SATISFACTION"
                      :default_value="score"
                      :disabled="disabled"
                      @scoreSelected="scoreSelected"/>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column">
        <b-field :label="$t('survey.labels.comments')">
          <b-input v-model="feedback" data-test="survey-comments-input" :disabled="disabled"
                   type="textarea"/>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import Rating from '@/app/shared_components/surveys/Rating.vue';
import YesNo from '@/app/shared_components/surveys/YesNo.vue';
import Satisfaction from '@/app/shared_components/surveys/Satisfaction.vue';
import { SurveyQuestion } from '@/app/data/model_constants';
import { mapGetters } from 'vuex';

export default {
  name: 'SurveyForm',
  components: { Satisfaction, YesNo, Rating },
  props: {
    disabled: { type: Boolean, default: false },
    title: { type: String, default: null },
    question_type: { type: String, default: null },
    question_number: { type: Number, default: null },
    questions: { type: Number, default: null },
  },

  emits: ['feedback-updated', 'score-selected'],

  data() {
    return {
      score: null,
      feedback: null,
      SurveyQuestion,
    };
  },

  computed: {
    ...mapGetters(['current_client']),

    title_survey() {
      if (this.question_type === SurveyQuestion.NPS) {
        return this.$t('survey.labels.NPSQuestion', { name: this.current_client.attributes.name })
      }
      if (this.title) return this.title
      return '...';
    },
  },

  watch: {
    title() {
      this.score = null;
      this.feedback = null;
    },

    feedback() {
      this.$emit('feedbackUpdated', this.feedback);
    },
  },

  methods: {
    scoreSelected(newScore) {
      this.$emit('scoreSelected', newScore);
      this.score = newScore;
    },
  },
};
</script>
