<template>
  <Popper
    hover
    :interactive="false"
    :openDelay="delay"
    :placement="placement">
    <template #content>
      <div class="popper_ziggu">
        <slot name="tip"/>
      </div>
    </template>
    <slot name="content" />
  </Popper>
</template>

<script>
import Popper from 'vue3-popper';

export default {
  name: 'VPopper',

  components: { Popper },

  props: {
    placement: { type: String, default: 'top' },
    delay: { type: Number, default: 300 },
  },
};
</script>
