import { ROUTES_EMPLOYEES } from '@/app/data/route_constants';

const routesDecision = [
  {
    name: ROUTES_EMPLOYEES.PROJECT_DECISION_REMINDERS,
    path: 'decision_information',
    component: () => import('@/app/_employees/subpages/decisions/TheDecisionRemindersSubpage.vue'),
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_DECISION_ACTIVITY,
    path: 'decision_activity',
    component: () => import('@/app/_employees/subpages/decisions/TheDecisionActivitySubpage.vue'),
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_DECISION_DOCUMENTS,
    path: 'decision_documents',
    component: () => import('@/app/shared_components/decisions/TheDecisionDocumentsTemplate.vue'),
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_DECISION_PROPOSALS,
    path: 'decision_proposals',
    component: () => import('@/app/_employees/subpages/decisions/TheDecisionProposalsSubpage.vue'),
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_DECISION_TICKETS,
    path: 'decision_tickets',
    component: () => import('@/app/_employees/subpages/decisions/TheDecisionTicketsSubpage.vue'),
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_DECISION_TICKETS_SHOW,
    path: 'decision_ticket/:ticket_id',
    component: () => import('@/app/_employees/subpages/decisions/TheDecisionTicketSubpage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_DECISION,
    path: '',
    redirect: { name: ROUTES_EMPLOYEES.PROJECT_DECISION_PROPOSALS },
  },
];

export default routesDecision;
