<template>
  <div class="navbar-sm-tenant level is-mobile is-marginless section">
    <div class="level-left">
      <b-dropdown
        append-to-body
        aria-role="menu"
        position="is-bottom-left">
        <template #trigger>
          <a
            class="navbar-item"
            role="button">
            <FontAwesomeIcon icon="bars" size="lg"/>
          </a>
        </template>
        <b-dropdown-item
          v-for="(item, idx) in NAVBAR_ITEMS_SUPERVISORS" :key="idx"
          aria-role="menuitem custom ">
          <router-link
            :to="{ name: item.name }">
            <FontAwesomeIcon
              class="mr-2"
              :icon="item.icon"/>
            {{ $t(item.title) }}
          </router-link>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="level-right">
      <router-link class="level-item topbar-item" :to="{ name: ROUTES_SUPERVISORS.DASHBOARD }">
        <span class="icon is-large">
          <i class="fas fa-book fa-lg"/>
        </span>
      </router-link>
      <HelpSidebar/>
      <ProfileDropdown/>
    </div>
  </div>
</template>

<script>
import { ROUTES_SUPERVISORS } from '@/app/data/route_constants';
import HelpSidebar from '@/app/shared_components/navbar/TheHelpSidebar.vue';
import ProfileDropdown from '@/app/shared_components/navbar/TheProfileDropdown.vue';
import { NAVBAR_ITEMS_SUPERVISORS } from '@/app/data/navbar_constants';

export default {
  components: {
    HelpSidebar,
    ProfileDropdown,
  },

  data() {
    return {
      NAVBAR_ITEMS_SUPERVISORS,
      ROUTES_SUPERVISORS,
    };
  },
};

</script>
