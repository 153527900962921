import { useMq } from 'vue3-mq';
import { ref, watch, reactive } from 'vue';

export const useMqService = () => {
  const mq = useMq();

  const isMobile = ref(false || (mq.current === 'sm' || mq.current === 'xs'));
  const isTablet = ref(false || (mq.current === 'md'));
  const isDesktop = ref(false || (mq.current === 'lg' || mq.current === 'xl' || mq.current === 'xxl'));
  const isDesktopWide = ref(false || (mq.current === 'xxl'));

  watch(() => mq.current, (value) => {
    isMobile.value = value === 'sm' || value === 'xs';
    isTablet.value = value === 'md';
    isDesktop.value = value === 'lg' || value === 'xl' || value === 'xxl';
    isDesktopWide.value = value === 'xxl';
  });

  return (reactive({
    isMobile,
    isTablet,
    isDesktop,
    isDesktopWide
  }));
};

export default useMqService;
