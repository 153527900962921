import { ROUTES_USERS } from '@/app/data/route_constants';

const userRoutes = [
  {
    name: ROUTES_USERS.DASHBOARD,
    path: 'dashboard',
    component: () => import('@/app/_users/pages/DashboardPage.vue'),
  },
];

export default userRoutes;
