export const EDITOR_CONTENT_HTML_EMPTY = '<p></p>';
export const EDITOR_CONTENT_HTML_EMPTY_WITH_COLLAPSED_SPACES = '<p> </p>';
export const EDITOR_CONTENT_HTML_EMPTY_PUBLIC_API = '{"type":"doc","content":[{"type":"paragraph"}]}';

export const isEmptyHTML = (value) => value === EDITOR_CONTENT_HTML_EMPTY
  || value === EDITOR_CONTENT_HTML_EMPTY_WITH_COLLAPSED_SPACES
  || value === EDITOR_CONTENT_HTML_EMPTY_PUBLIC_API;

export const EDITOR_TYPES = Object.freeze({
  REPLY: 'reply_editor',
  NOTE: 'note_editor',
});
