import current from './modules/current';
import sidebar from './modules/sidebar';
import translations from './modules/translations';
import feature_toggles from './modules/feature_toggles';

export default {
  modules: {
    current,
    sidebar,
    translations,
    feature_toggles
  }
}
