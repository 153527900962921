<template>
  <div
    class="button has-text-weight-medium"
    :class="[is_outlined ? 'is-outlined' : '', is_light ? 'has-text-grey' : 'is-danger']"
    :disabled="(is_disabled || null)"
    @click="submit">
    <div v-if="!is_loading" class="icon">
      <FontAwesomeIcon icon="trash-can"/>
    </div>
    <div v-if="is_loading" class="icon">
      <FontAwesomeIcon icon="spinner" pulse/>
    </div>
    <div v-if="$slots.default">
      <slot/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ButtonDestructive',
  props: {
    is_outlined: { type: Boolean },
    is_light: { type: Boolean },
    is_disabled: { type: Boolean },
    is_loading: { type: Boolean },
  },

  emits: ['onClick'],

  methods: {
    submit() {
      if (this.is_disabled) return;
      this.$emit('onClick');
    },
  },
};
</script>
