<template>
  <div v-if="initialized" id="app"
       :class="{
         'environment-staging-border': environment === VITE_ENV_STAGING,
         'environment-development-border': environment === VITE_ENV_DEVELOPMENT,
         'environment-review-border': environment === VITE_ENV_REVIEW,
         'impersonate-border': current_profile_true.type === PROFILE_TYPES.EMPLOYEE
       }">
    <div v-if="current_profile_true.type === PROFILE_TYPES.EMPLOYEE"
         class="impersonate-button"
         @click="emitter.$emit('stopImpersonate')">
      <div class="button is-white is-outlined">
        {{ $t('contact.actions.impersonateStop') }} {{ current_profile.attributes.name }}
      </div>
    </div>
    <div v-if="project_id"
         :class="{ 'ziggu_sm': mq.isMobile || mq.isTablet, 'ziggu_lg': mq.isDesktop }">
      <NavbarTablet v-if="mq.isMobile || mq.isTablet"/>
      <NavbarDesktop v-else/>
      <TopbarDesktop v-if="mq.isDesktop"/>
      <TopbarTablet v-else/>
      <OutOfOfficeBanner
        v-if="current_client.attributes.ooo"
        :client="current_client"
        :profile="current_profile"/>
      <main id="page" :class="{'sidebar-padding': mq.isDesktopWide && sidebar_type }">
        <div class="section container">
          <router-view/>
        </div>
      </main>
      <SurveysPopup
        v-if="surveys_popup_active"
        :project_id="project_id"
        :survey_id="active_survey_id"
        @close="closeSurveysPopup"/>
    </div>
  </div>
  <div v-else>
    <b-loading :is-full-page="true" :model-value="!initialized"/>
  </div>
</template>

<script>
import NavbarDesktop from '@/app/_customers/components/navbar/TheNavbarDesktop.vue';
import NavbarTablet from '@/app/_customers/components/navbar/TheNavbarTablet.vue';
import OutOfOfficeBanner from '@/app/shared_components/banner/TheOutOfOfficeBanner.vue';
import SurveysPopup from '@/app/shared_components/surveys/SurveysPopup.vue';
import TopbarDesktop from '@/app/shared_components/navbar/TheTopbarDesktop.vue';
import TopbarTablet from '@/app/shared_components/navbar/TheTopbarTablet.vue';
import { mapActions, mapGetters } from 'vuex';
import { Tenant } from '@/app/data/model_constants';
import { ROUTES_CUSTOMERS } from '@/app/data/route_constants';
import profile_mixin from '@/app/util/profile_mixin';
import { useMqService } from '@/plugins/mq';

export default {
  name: 'Customer',
  components: {
    NavbarDesktop,
    NavbarTablet,
    OutOfOfficeBanner,
    SurveysPopup,
    TopbarDesktop,
    TopbarTablet,
  },
  mixins: [profile_mixin],

  setup() {
    const mq = useMqService();
    return { mq };
  },

  data() {
    return {
      active_survey_id: null,
      surveys_popup_active: true,
    };
  },

  computed: {
    ...mapGetters([
      'current_client',
      'current_profile',
    ]),
    environment() { return import.meta.env.VITE_ENV; },

    is_birthday() {
      if (this.current_client.attributes.status === Tenant.STATUS_TEST) return false;

      const birth_date = new Date(this.current_profile.attributes.birth_date);
      const today = new Date();
      return birth_date && birth_date.getMonth() === today.getMonth() && birth_date.getDate() === today.getDate();
    },
  },

  watch: {
    project_id: {
      handler() {
        this.surveys_popup_active = true;
      },
    },
  },

  async created() {
    // survey popup handler
    this.$root.emitter.$on('initSurvey', (survey_id) => {
      this.active_survey_id = survey_id;
      this.surveys_popup_active = true;
    });

    await this.initProfile();
    await this.initProject();
    this.initConfetti();
  },

  methods: {
    ...mapActions([
      'FETCH_UNITS',
    ]),

    async fetchUnits() {
      await this.FETCH_UNITS();
    },

    closeSurveysPopup() {
      this.surveys_popup_active = false;
      this.active_question_id = null;
      this.initConfetti();
    },

    async initProject() {
      if (_.isEmpty(this.projects)) {
        // customer does not have any (visible) units: trigger logout
        this.emitter.$emit('logout');
      } else {
        // project_id route param takes precedence, scoped with fallback to first unit's project
        let project_id = this.$route.params.project_id;
        if (project_id && !(project_id in this.projects)) {
          project_id = null;
        }
        if (!project_id) {
          const project = _.chain(this.projects)
            .values(this.projects)
            .maxBy('attributes.created_at')
            .value();
          project_id = project.id;
          let route_name = this.$route.name;
          // convert "root" routes to project namespaced routes
          if (this.$route.name === ROUTES_CUSTOMERS.ROOT) {
            route_name = ROUTES_CUSTOMERS.DASHBOARD;
          } else if (this.$route.name === ROUTES_CUSTOMERS.ROOT_SETTINGS_PROFILE) {
            route_name = ROUTES_CUSTOMERS.SETTINGS_PROFILE;
          }
          this.$router.replace({ name: route_name, params: { project_id } });
        }
      }
    },

    initConfetti() {
      if (!this.is_birthday) return;
      if (this.surveys_popup_active) return; // no confetti with survey modal, invoke on closeSurveysPopup()

      const confetti_at = new Date().getFullYear().toString();
      const confettied_at = localStorage.getItem('confettied_at');
      if (confettied_at && confettied_at === confetti_at) return;
      this.$buefy.toast.open({
        message: this.$t('customer.toasts.birthday', { first_name: this.current_profile.attributes.first_name }),
        type: 'is-success',
        duration: 5000,
      });
      this.$confetti.start();
      setTimeout(() => this.$confetti.stop(), 5000);
      localStorage.setItem('confettied_at', confetti_at);
    },
  },
};
</script>
