<template>
  <div>
    <div v-for="score in scores" :key="score.value"
         class="button mb-3"
         :class="{ 'is-primary': score.active, 'is-outlined is-primary': !score.active }"
         style="height: 40px; width: 100%; margin: 0 0.3rem;"
         @click="!disabled && selectAnswer(score.value)">
      <div>
        {{ score_map[score.value] }}
      </div>
    </div>
  </div>
</template>

<script>

const SCORES_DEFAULT = [
  { active: false, value: 0 },
  { active: false, value: 1 },
  { active: false, value: 2 },
  { active: false, value: 3 },
  { active: false, value: 4 },
];

export default {
  name: 'Satisfaction',
  props: {
    disabled: { type: Boolean, default: false },
    default_value: { type: Number, default: null },
  },

  data() {
    const scores = _.cloneDeep(SCORES_DEFAULT);
    if (!_.isNil(this.default_value)) scores[this.default_value].active = true;
    return { scores };
  },

  computed: {
    score_map() {
      return {
        0: this.$t('survey.labels.very_satisfied'),
        1: this.$t('survey.labels.satisfied'),
        2: this.$t('survey.labels.neither_satisfied_nor_dissatisfied'),
        3: this.$t('survey.labels.dissatisfied'),
        4: this.$t('survey.labels.very_dissatisfied'),
      };
    },
  },

  watch: {
    default_value() {
      this.scores = _.cloneDeep(SCORES_DEFAULT);
      if (!_.isNil(this.default_value)) this.scores[this.default_value].active = true;
    },
  },

  methods: {
    selectAnswer(answer) {
      this.scores = _.cloneDeep(SCORES_DEFAULT);
      this.scores[answer].active = true;
      this.$emit('scoreSelected', this.scores[answer].value);
    },
  },
};
</script>
