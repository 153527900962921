/* eslint-disable prefer-destructuring */
import { mapActions, mapGetters } from 'vuex';
import { PROFILE_TYPES, RECORD_TYPE } from '@/app/data/model_constants';
import can_open_implicitly_mixin from '@/app/util/can_open_implicitly_mixin';

const activity_notification_open_mixin = {
  data() {
    return {
      // mapping of notifiable keys ({notifiable_type}_{notifiable_id}) to unopened status (true/undefined)
      notifiables_is_unopened: {},
    };
  },

  mixins: [can_open_implicitly_mixin],
  computed: {
    ...mapGetters([
      'activities',
      'current_is_demo',
      'current_is_impersonation',
      'current_profile_true',
      'current_client',
      'notifications',
      'current_profile',
    ]),

    /**
     * isNotifiableUnopened() returns whether or not this notifiable has corresponding unopened notifications
     * @param notifiable
     * @returns {boolean}
     */
    is_notifiable_unopened() {
      return (notifiable) => !!this.notifiables_is_unopened[`${notifiable.type}_${notifiable.id}`];
    },
  },

  methods: {
    ...mapActions([
      'OPEN_ANNOUNCEMENT_ACTIVITIES',
      'OPEN_ANNOUNCEMENT_NOTIFICATIONS',
      'OPEN_DOCUMENT_NOTIFICATIONS',
      'OPEN_DOCUMENT_ACTIVITIES',
      'OPEN_INSTALLMENT_NOTIFICATIONS',
      'OPEN_ISSUE_NOTIFICATIONS',
      'OPEN_ISSUE_LIST_NOTIFICATIONS',
      'OPEN_QUESTION_ACTIVITIES',
      'OPEN_QUESTION_NOTIFICATIONS',
      'OPEN_DECISION_ACTIVITIES',
      'OPEN_DECISION_NOTIFICATIONS',
      'OPEN_DECISION_PROPOSAL_ACTIVITIES',
      'OPEN_DECISION_PROPOSAL_NOTIFICATIONS',
      'OPEN_EXPORT_NOTIFICATIONS',
      'OPEN_SURVEY_ACTIVITIES',
      'OPEN_SURVEY_NOTIFICATIONS',
      'OPEN_TICKET_ACTIVITIES',
      'OPEN_TICKET_NOTIFICATIONS',
      'OPEN_EMAIL_CONVERSATION_NOTIFICATIONS',
      'OPEN_TODO_NOTIFICATIONS',
    ]),

    async openAnnouncementNotificationsImplicit(notifiables) {
      if (!this.can_open_implicitly) return;
      await this.openAnnouncementNotifications(notifiables);
    },

    async openDocumentNotificationsImplicit(notifiables) {
      if (!this.can_open_implicitly) return;
      await this.openDocumentNotifications(notifiables);
    },

    async openExportNotificationsImplicit(notifiables) {
      if (!this.can_open_implicitly) return;
      await this.openExportNotifications(notifiables);
    },

    async openInstallmentNotificationsImplicit(notifiables) {
      if (!this.can_open_implicitly) return;
      await this.openInstallmentNotifications(notifiables);
    },

    async openIssueNotificationsImplicit(notifiables) {
      if (!this.can_open_implicitly) return;
      await this.openIssueNotifications(notifiables);
    },

    async openIssueListNotificationsImplicit(issue_lists) {
      if (!this.can_open_implicitly) return;
      await this.openIssueListNotifications(issue_lists);
    },

    async openQuestionNotificationsImplicit(notifiables) {
      if (!this.can_open_implicitly) return;
      await this.openQuestionNotifications(notifiables);
    },

    async openSurveyNotificationsImplicit(notifiables) {
      if (!this.can_open_implicitly) return;
      await this.openSurveyNotifications(notifiables);
    },

    async openSurveyNotificationsExplicit(notifiables) {
      await this.openSurveyNotifications(notifiables);
    },

    async openTicketNotificationsImplicit(notifiables, ticket_id) {
      if (!this.can_open_implicitly) return;
      await this.openTicketNotifications(notifiables, ticket_id);
    },

    async openEmailConversationNotificationsImplicit(notifiables, email_conversation_id) {
      if (!this.can_open_implicitly) return;
      await this.openEmailConversationNotifications(notifiables, email_conversation_id);
    },

    async openTodoNotificationsImplicit(notifiables) {
      if (!this.can_open_implicitly) return;
      await this.openTodoNotifications(notifiables);
    },

    async openDecisionNotificationsImplicit(notifiables, decision_id) {
      if (!this.can_open_implicitly) return;
      await this.openDecisionNotifications(notifiables, decision_id);
    },

    async openDecisionProposalNotificationsImplicit(notifiables, decision_id) {
      if (!this.can_open_implicitly) return;
      await this.openDecisionProposalNotifications(notifiables, decision_id);
    },

    async openAnnouncementNotifications(notifiables) {
      const notifications = this.filterNotifications(notifiables, this.current_profile);
      const notifiable_type = this.current_profile.type === PROFILE_TYPES.CUSTOMER
        ? RECORD_TYPE.ANNOUNCEMENT_LEVEL // customers only have notifications via AnnouncementLevel model
        : RECORD_TYPE.ANNOUNCEMENT; // employeees only have notifications via Announcement model
      const ids = _.chain(notifications)
        .filter(['attributes.notifiable_type', notifiable_type])
        .map('attributes.notifiable_id')
        .value();
      if (ids.length === 0) return;

      await this.OPEN_ANNOUNCEMENT_NOTIFICATIONS({ ids });
    },

    async openDocumentNotifications(notifiables) {
      const notifications = this.filterNotifications(notifiables, this.current_profile);
      const ids = _.chain(notifications)
        .filter(['attributes.notifiable_type', RECORD_TYPE.DOCUMENT])
        .map('attributes.notifiable_id')
        .value();
      if (ids.length === 0) return;

      await this.OPEN_DOCUMENT_NOTIFICATIONS({ ids });
    },

    async openExportNotifications(notifiables) {
      const notifications = this.filterNotifications(notifiables, this.current_profile);
      const ids = _.chain(notifications)
        .filter('attributes.notifiable_type', RECORD_TYPE.EXPORT)
        .map('attributes.notifiable_id')
        .value();
      if (ids.length === 0) return;

      await this.OPEN_EXPORT_NOTIFICATIONS({ ids });
    },

    async openInstallmentNotifications(notifiables) {
      const notifications = this.filterNotifications(notifiables, this.current_profile);
      const installment_ids = _.chain(notifications)
        .filter(['attributes.notifiable_type', RECORD_TYPE.INSTALLMENT])
        .map('attributes.notifiable_id')
        .value();
      if (installment_ids.length === 0) return;

      await this.OPEN_INSTALLMENT_NOTIFICATIONS({ ids: installment_ids });
    },

    // customers only have issue notifications as of right now (no notifications on issue lists)
    async openIssueNotifications(notifiables) {
      const notifications = this.filterNotifications(notifiables, this.current_profile);
      const issue_ids = _.chain(notifications)
        .filter(['attributes.notifiable_type', RECORD_TYPE.ISSUE])
        .map('attributes.notifiable_id')
        .value();
      if (issue_ids.length === 0) return;

      await this.OPEN_ISSUE_NOTIFICATIONS({ ids: issue_ids });
    },

    async openIssueListNotifications(issue_lists) {
      const notifications = this.filterNotifications(issue_lists, this.current_profile);
      const issue_list_ids = _.chain(notifications)
        .filter(['attributes.notifiable_type', RECORD_TYPE.ISSUE_LIST])
        .map('attributes.notifiable_id')
        .value();
      if (issue_list_ids.length === 0) return;

      await this.OPEN_ISSUE_LIST_NOTIFICATIONS({ ids: _.map(issue_lists, 'id') });
    },

    async openQuestionNotifications(questions) {
      const notifications = this.filterNotifications(questions, this.current_profile);
      const question_ids = _.chain(notifications)
        .filter(['attributes.notifiable_type', RECORD_TYPE.QUESTION])
        .map('attributes.notifiable_id')
        .value();
      if (question_ids.length === 0) return;

      await this.OPEN_QUESTION_NOTIFICATIONS({ ids: question_ids });
    },

    async openSurveyNotifications(notifiables) {
      const notifications = this.filterNotifications(notifiables, this.current_profile);
      const survey_ids = _.chain(notifications)
        .filter(['attributes.notifiable_type', RECORD_TYPE.SURVEY])
        .map('attributes.notifiable_id')
        .value();
      if (survey_ids.length === 0) return;

      await this.OPEN_SURVEY_NOTIFICATIONS({ ids: survey_ids });
    },

    async openTicketNotifications(notifiables, ticket_id) {
      const notifications = this.filterNotifications(notifiables, this.current_profile);
      if (notifications.length === 0) return;

      await this.OPEN_TICKET_NOTIFICATIONS({ ids: [ticket_id] });
    },

    async openEmailConversationNotifications(notifiables, email_conversation_id) {
      const notifications = this.filterNotifications(notifiables, this.current_profile);
      if (notifications.length === 0) return;

      await this.OPEN_EMAIL_CONVERSATION_NOTIFICATIONS({ ids: [email_conversation_id] });
    },

    async openTodoNotifications(notifiables) {
      const notifications = this.filterNotifications(notifiables, this.current_profile);
      const todo_ids = _.chain(notifications)
        .filter(['attributes.notifiable_type', RECORD_TYPE.TODO])
        .map('attributes.notifiable_id')
        .value();
      if (todo_ids.length === 0) return;

      await this.OPEN_TODO_NOTIFICATIONS({ ids: todo_ids });
    },

    async openDecisionNotifications(notifiables, decision_id) {
      const notifications = this.filterNotifications(notifiables, this.current_profile);
      if (notifications.length === 0) return;

      await this.OPEN_DECISION_NOTIFICATIONS({ ids: [decision_id] });
    },

    async openDecisionProposalNotifications(notifiables, decision_id) {
      const notifications = this.filterNotifications(notifiables, this.current_profile);
      if (notifications.length === 0) return;

      await this.OPEN_DECISION_PROPOSAL_NOTIFICATIONS({ ids: [decision_id] });
    },

    filterNotifications(notifiables, target) {
      let notifications = [];
      if (notifiables
        && (('id' in notifiables)
          || (Array.isArray(notifiables) && notifiables.length > 0)
          || (!_.isEmpty(notifiables)))) {
        // differentiate single element or collection (Array/Object)
        let notifiable_ids;
        let notifiable_types;
        if ('id' in notifiables) {
          notifiable_ids = { [notifiables.type]: notifiables.id };
          notifiable_types = [notifiables.type];
        } else {
          notifiable_ids = {};
          _.each(notifiables, (notifiable) => {
            if (!(notifiable.type in notifiable_ids)) notifiable_ids[notifiable.type] = [];
            notifiable_ids[notifiable.type].push(notifiable.id);
          });
          notifiable_types = _.keys(notifiable_ids);
        }
        notifications = _.filter(
          target.type === RECORD_TYPE.UNIT ? this.activities : this.notifications,
          (notification) => notifiable_types.includes(notification.attributes.notifiable_type)
            && notifiable_ids[notification.attributes.notifiable_type].includes(notification.attributes.notifiable_id)
            && notification.attributes.target_type === target.type
            && (target.id == null || notification.attributes.target_id === target.id)
            && (notification.attributes.opened_at === null || notification.attributes.read_at === null),
        );
      }
      return notifications;
    },

    filterActivities(notifiables) {
      return this.filterNotifications(notifiables, { type: RECORD_TYPE.UNIT, id: null });
    },

    /**
     * openActivitiesImplicit() finds possible corresponding activities and marks them as read
     * @param notifiables Object, can be a single element/JSON-normalized object/array of JSON-objects
     */
    async openActivitiesImplicit(notifiables) {
      if (!this.can_open_implicitly) return;
      await this.openActivities(notifiables);
    },

    async openAnnouncementActivitiesImplicit(notifiables) {
      if (!this.can_open_implicitly) return;
      await this.openAnnouncementActivities(notifiables);
    },

    async openDecisionActivitiesImplicit(notifiables) {
      if (!this.can_open_implicitly) return;
      await this.openDecisionActivities(notifiables);
    },

    async openDecisionProposalActivitiesImplicit(notifiables) {
      if (!this.can_open_implicitly) return;
      await this.openDecisionProposalActivities(notifiables);
    },

    async openDocumentActivitiesImplicit(notifiables) {
      if (!this.can_open_implicitly) return;
      await this.openDocumentActivities(notifiables);
    },

    async openQuestionActivitiesImplicit(notifiables) {
      if (!this.can_open_implicitly) return;
      await this.openQuestionActivities(notifiables);
    },

    async openSurveyActivitiesImplicit(notifiables) {
      if (!this.can_open_implicitly) return;
      await this.openSurveyActivities(notifiables);
    },

    async openTicketActivitiesImplicit(notifiables, ticket_id) {
      if (!this.can_open_implicitly) return;
      await this.openTicketActivities(notifiables, ticket_id);
    },

    async openAnnouncementActivities(notifiables) {
      const activities = this.filterActivities(notifiables);
      const announcement_ids = _.chain(activities)
        .filter('attributes.notifiable_type', RECORD_TYPE.ANNOUNCEMENT_LEVEL)
        .map('attributes.parameters.announcement_id')
        .value();
      if (announcement_ids.length === 0) return;

      await this.OPEN_ANNOUNCEMENT_ACTIVITIES({ ids: [announcement_ids] });
    },

    async openDecisionActivities(notifiables) {
      const activities = this.filterActivities(notifiables);
      const decision_ids = _.map(activities, 'attributes.parameters.decision_id');
      if (decision_ids.length === 0) return;

      await this.OPEN_DECISION_ACTIVITIES({ ids: decision_ids });
    },

    async openDecisionProposalActivities(notifiables) {
      const activities = this.filterActivities(notifiables);
      const decision_proposal_ids = _.chain(activities)
        .filter('attributes.notifiable_type', RECORD_TYPE.DECISION_PROPOSAL)
        .map('attributes.notifiable_id')
        .value();
      if (decision_proposal_ids.length === 0) return;

      await this.OPEN_DECISION_PROPOSAL_ACTIVITIES({ ids: decision_proposal_ids });
    },

    async openDocumentActivities(notifiables) {
      const activities = this.filterActivities(notifiables);
      const document_ids = _.chain(activities)
        .filter('attributes.notifiable_type', RECORD_TYPE.DOCUMENT)
        .map('attributes.notifiable_id')
        .value();
      if (document_ids.length === 0) return;

      await this.OPEN_DOCUMENT_ACTIVITIES({ ids: document_ids });
    },

    async openQuestionActivities(notifiables) {
      const activities = this.filterActivities(notifiables);
      const question_ids = _.chain(activities)
        .filter('attributes.notifiable_type', RECORD_TYPE.QUESTION)
        .map('attributes.notifiable_id')
        .value();
      if (question_ids.length === 0) return;

      await this.OPEN_QUESTION_ACTIVITIES({ ids: question_ids });
    },

    async openSurveyActivities(notifiables) {
      const activities = this.filterActivities(notifiables);
      const survey_ids = _.chain(activities)
        .filter('attributes.notifiable_type', RECORD_TYPE.SURVEY)
        .map('attributes.notifiable_id')
        .value();
      if (survey_ids.length === 0) return;

      await this.OPEN_SURVEY_ACTIVITIES({ ids: survey_ids });
    },

    async openTicketActivities(notifiables, ticket_id) {
      const activities = this.filterActivities(notifiables);
      if (activities !== null && activities.length !== 0) {
        this.OPEN_TICKET_ACTIVITIES({ ids: [ticket_id] });
      }
    },

    /**
     * trackNotifiablesUnopened() tracks the notifiables which have unopened notifications,
     *    method is typically invoked before implicit/explicit opens, in order to highlight new content after open
     * @param notifiables
     */
    trackNotifiablesUnopened(notifiables) {
      if (notifiables
          && (('id' in notifiables)
              || (Array.isArray(notifiables) && notifiables.length > 0)
              || (!_.isEmpty(notifiables)))) {
        // differentiate single element or multiple elements via either array or object
        let notifiableIds;
        let type;

        if ('id' in notifiables) {
          notifiableIds = [notifiables.id];
          type = notifiables.type;
        } else if (Array.isArray(notifiables)) {
          notifiableIds = _.map(notifiables, 'id');
          type = notifiables[0].type;
        } else {
          notifiableIds = Object.keys(notifiables);
          type = notifiables[notifiableIds[0]].type;
        }
        const notifiableNotifications = _.filter(
          this.notifications,
          (notification) => notifiableIds.includes(notification.attributes.notifiable_id)
              && notification.attributes.notifiable_type === type
              && notification.attributes.target_type === this.current_profile.type
              && notification.attributes.target_id === this.current_profile.id
              && (notification.attributes.opened_at === null || notification.attributes.read_at === null),
        );
        notifiableNotifications.forEach((notification) => {
          const { notifiable_id } = notification.attributes;
          const { notifiable_type } = notification.attributes;
          this.notifiables_is_unopened[`${notifiable_type}_${notifiable_id}`] = true;
        });
      }
    },
  },
};

export default activity_notification_open_mixin;
