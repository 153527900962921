import { ref } from 'vue'
import createAuth0Client from '@auth0/auth0-spa-js';
import * as Sentry from '@sentry/vue';

// code in sync with help/docs/.vuepress/auth.js

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState(window.history.state, document.title, window.location.pathname);

const instance = ref(null);

export const getInstance = () => instance.value;

class Auth0 {
  loading = ref(true);

  onRedirectCallback = null;

  is_authenticated = ref(false);

  user = ref({});

  auth0Client = null;

  error = ref(null);

  options = null;

  constructor({
    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
    redirectUri = window.location.origin,
    audience,
    client_id,
    domain
  } = {}) {
    const scope = 'openid email profile';
    const auth0_local_storage_key = `@@auth0spajs@@::${client_id}::${audience}::${scope}`;
    let is_cypress;
    try {
      is_cypress = localStorage.getItem(auth0_local_storage_key) !== null;
    // localStorage being null due to access denied (e.g. private mode Safari) or storage full, assume not cypress
    } catch (e) {
      is_cypress = false
    }

    this.onRedirectCallback = onRedirectCallback;
    this.options = {
      audience,
      client_id,
      domain,
      redirect_uri: redirectUri,
      useRefreshTokens: !is_cypress,
      cacheLocation: 'localstorage',
    };

    instance.value = this;
  }

  async init() {
    this.auth0Client = await createAuth0Client(this.options);
    await this.checkSession();
    try {
      if (
        window.location.search.includes('code=')
        && window.location.search.includes('state=')
      ) {
        await this.handleRedirectCallback();
        this.error.value = null;
        this.onRedirectCallback();
      }
    } catch (e) {
      this.error.value = e;
    } finally {
      this.is_authenticated.value = await this.auth0Client.isAuthenticated();
      if (this.is_authenticated.value) {
        this.user.value = await this.auth0Client.getUser();
      } else {
        this.user.value = null;
      }
      this.loading.value = false;
    }
  }

  async handleRedirectCallback() {
    // this.loading.value = true;
    try {
      await this.auth0Client.handleRedirectCallback();
      this.user.value = await this.auth0Client.getUser();
      this.is_authenticated.value = true;
      this.error.value = null;
    } catch (e) {
      this.error.value = e;
    }// finally {
    //   this.loading.value = false;
    // }
  }

  async loginWithRedirect() {
    const ziggu_base_url = window.location.origin;

    // retrieve and append any (present) ziggu parameters to pass onto auth0 login page
    // We can't use URLSearchParams because it's not supported on IE
    const targetUrl = window.location.pathname;
    const params = _.chain(window.location.search)
      .replace('?', '') // remove starting ?
      .split('&')
      .map(_.partial(_.split, _, '=', 2))
      .fromPairs()
      .value();
    const ziggu_email_address = decodeURIComponent(params.ziggu_email_address || '');
    // TODO: can we differentiate clean slate login vs session expired (LOGIN_PAGE_NOTIFICATIONS.SESSION_EXPIRED)
    const ziggu_notification = decodeURIComponent(params.ziggu_notification || 'login');

    await this.auth0Client.loginWithRedirect({
      appState: { targetUrl, params },
      ziggu_base_url,
      ziggu_email_address,
      ziggu_notification,
    });
  }

  async getToken(o) {
    let token;
    try {
      token = localStorage.getItem('_jwt');
      if (token) return token;

      token = await this.auth0Client.getTokenSilently(o);
    } catch (error) {
      // skip logging expected errors to sentry
      if (error && error.error !== 'login_required') Sentry.captureException(error);
    } finally {
      // failed silent authentication (error or no token): redirect to universal login
      if (!token) await this.loginWithRedirect();
    }
    return token;
  }

  getUser() {
    return this.auth0Client.getUser();
  }

  logout(o) {
    return this.auth0Client.logout(o);
  }

  async checkSession() {
    // invalidate local application session, re-authenticate via auth0 session:
    // - enforces single logout on (re)load: logout on a single (sub)domain will trigger logout on all (sub)domains
    // - enforcing single logout on route navigation: done via user integrity
    await this.auth0Client.checkSession({ ignoreCache: true });
  }
}

/* eslint-disable no-shadow,no-param-reassign,camelcase */

export const Auth0Plugin = {
  install(app, options) {
    const auth = new Auth0(options);
    auth.init();
    app.config.globalProperties.$auth = instance.value;
  },
};
