import { ROUTES_CUSTOMERS } from '@/app/data/route_constants';

const routesDecision = [
  {
    name: ROUTES_CUSTOMERS.DECISION_TICKETS,
    path: 'decision_tickets',
    component: () => import('@/app/_customers/subpages/decisions/TheDecisionTicketsSubpage.vue'),
    props: true,
  },
  {
    name: ROUTES_CUSTOMERS.DECISION_TICKET_SHOW,
    path: 'decision_ticket/:ticket_id',
    component: () => import('@/app/_customers/subpages/decisions/TheDecisionTicketSubpage.vue'),
    props: true,
  },
  {
    name: ROUTES_CUSTOMERS.DECISION_DOCUMENTS,
    path: 'decision_documents',
    component: () => import('@/app/_customers/subpages/decisions/TheDecisionDocumentsSubpage.vue'),
  },
  {
    name: ROUTES_CUSTOMERS.DECISION_PROPOSALS,
    path: 'decision_proposals',
    component: () => import('@/app/_customers/subpages/decisions/TheDecisionProposalsSubpage.vue'),
  },
  {
    name: ROUTES_CUSTOMERS.DECISION,
    path: '',
    redirect: { name: ROUTES_CUSTOMERS.DECISION_PROPOSALS },
  },
];

export default routesDecision;
