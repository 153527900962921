<template>
  <nav id="topbar" class="level flex_breadcrumbs mb-0">
    <div class="level-left navbar-item">
      <breadcrumbs :is_medium_size="false"/>
    </div>
  </nav>
</template>

<script>

export default {
  name: 'TopbarTablet',
};
</script>

<style lang="css">
.flex_breadcrumbs {
  padding: 3px 15px;
  display: flex;
  align-items: center;
}
</style>
