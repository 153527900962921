<template>
  <Sidebar
    :pt="{
      content: 'pt-0 h-full w-full overflow-hidden'
    }"
    style="width: 32rem;"
    :visible="show_project_sidebar"
    @update:visible="$emit('closeProjectSidebar');">
    <template #header>
      <span class="font-semibold text-2xl">
        {{ $t('navbar.employee.projects') }}
      </span>
    </template>
    <div class="flex flex-col h-full">
      <div class="px-5">
        <b-input v-model="search_project" :placeholder="$t('project.labels.searchProject')"/>
      </div>
      <div class="mx-4 mt-5 mb-2">
        <div class="w-full flex items-center cursor-pointer px-4 py-1 h-9
        rounded-md text-surface-700 dark:text-surface-0/80 hover:bg-surface-100
        dark:hover:bg-surface-700 duration-200 transition-colors">
          <div class="text-surface-700 dark:text-surface-0/80 grow truncate"
               @click="goToOverview">
            {{ $t('navbar.employee.all_projects') }}
          </div>
        </div>
      </div>

      <div class="overflow-auto p-1 mb-4 px-3">
        <div
          v-ripple
          class="p-3 flex items-center text-surface-600 dark:text-surface-400 cursor-pointer rounded-md"
          @click="toggle_projects_follow = !toggle_projects_follow">
          <div class="mr-1">
            <span v-if="toggle_projects_follow" class="mr-1">
              <FontAwesomeIcon class="has-text-grey" icon="chevron-right"/>
            </span>
            <span v-else>
              <FontAwesomeIcon class="has-text-grey" icon="chevron-down" />
            </span>
          </div>
          {{ $t('project.labels.followedProjects') }}
        </div>
        <ul v-if="toggle_projects_follow" class="list-none px-4 m-0">
          <li
            v-for="project in projects_follow" :key="project.id">
            <SidebarProjectItem
              :assignments="assignments"
              :is_following_project="true"
              :project="project"
              :toggled_project_id="toggled_project_id"
              @closeProjectSidebar="$emit('closeProjectSidebar')"
              @follow="assignment => CREATE_ASSIGNMENT({ record: assignment })"
              @toggleProjectId="toggleProject"
              @unfollow="assignment => DELETE_ASSIGNMENT({ record: assignment })"/>
          </li>
        </ul>

        <div
          v-ripple
          class="p-3 flex items-center text-surface-600 dark:text-surface-400 cursor-pointer rounded-md"
          @click="toggleOtherProjects">
          <div class="mr-1">
            <span v-if="toggle_projects_other" class="mr-1">
              <FontAwesomeIcon class="has-text-grey" icon="chevron-right"/>
            </span>
            <span v-else>
              <FontAwesomeIcon class="has-text-grey" icon="chevron-down"/>
            </span>
            {{ $t('project.labels.otherProjects') }}
          </div>
        </div>
        <ul v-if="toggle_projects_other" class="list-none px-4 m-0">
          <li
            v-for="project in projects_other" :key="project.id">
            <SidebarProjectItem
              :assignments="assignments"
              :project="project"
              :toggled_project_id="toggled_project_id"
              @closeProjectSidebar="$emit('closeProjectSidebar')"
              @follow="assignment => CREATE_ASSIGNMENT({ record: assignment })"
              @toggleProjectId="toggleProject"
              @unfollow="assignment => DELETE_ASSIGNMENT({ record: assignment })"/>
          </li>
        </ul>
        <div
          v-ripple
          class="p-3 flex items-center text-surface-600 dark:text-surface-400 cursor-pointer rounded-md"
          @click="toggleProjectTemplates">
          <div class="mr-1">
            <span v-if="toggle_projects_template" class="mr-1">
              <FontAwesomeIcon class="has-text-grey" icon="chevron-right"/>
            </span>
            <span v-else>
              <FontAwesomeIcon class="has-text-grey" icon="chevron-down"/>
            </span>
            {{ $t('project.labels.templateProjects') }}
          </div>
        </div>
        <ul v-if="toggle_projects_template" class="list-none px-4 m-0">
          <li
            v-for="project in projects_template" :key="project.id">
            <SidebarProjectItem
              :assignments="assignments"
              :is_template="true"
              :project="project"
              :toggled_project_id="toggled_project_id"
              @closeProjectSidebar="$emit('closeProjectSidebar')"
              @toggleProjectId="toggleProject"/>
          </li>
        </ul>
      </div>
      <div v-if="current_profile.attributes.role === Employee.ROLE_OWNER" class="mt-auto shrink-0">
        <div class="mx-4 mb-2">
          <ButtonPrimary
            action="create"
            class="w-full mb-3"
            data-test="create-project-new"
            @onClick="goToProjectCreate">
            {{ $t('project.labels.project') }}
          </ButtonPrimary>
        </div>
      </div>
    </div>
  </Sidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ROUTES_EMPLOYEE_OVERVIEWS, ROUTES_EMPLOYEES } from '@/app/data/route_constants';
import {
  Assignment, Employee,
  LEVEL_TYPE,
  Project, Tenant,
} from '@/app/data/model_constants';
import SidebarProjectItem from '@/app/_employees/components/sidebars/SidebarProjectItem.vue';
import TheProjectModal from '@/app/_employees/components/projects/form/TheProjectModal.vue';

export default {
  name: 'TheProjectSideBar',
  components: {
    SidebarProjectItem
  },
  props: {
    show_project_sidebar: { type: Boolean }
  },

  data() {
    return {
      assignments_employee: [],
      Employee,
      loaded_other_projects: false,
      loaded_project_templates: false,
      ROUTES_EMPLOYEES,
      search_project: '',
      toggled_project_id: null,
      toggle_projects_follow: true,
      toggle_projects_other: false,
      toggle_projects_template: false,
      Tenant,
    };
  },

  computed: {
    ...mapGetters(['assignments', 'projects', 'current_profile', 'current_client']),

    projects_follow() {
      // TODO: this needs pagination with backend filtering
      return _.chain(this.projects)
        .pick(this.assignments_employee)
        .filter({ attributes: { status: Project.STATUS_ENABLED, skip_customer: false } })
        .filter((project) => {
          if (this.search_project) {
            const project_name = project.attributes.name.toLowerCase();
            const search_query = this.search_project.toLowerCase();
            return project_name.includes(search_query);
          }
          return true;
        })
        .orderBy(['attributes.name'], ['asc'])
        .value();
    },

    projects_other() {
      // TODO: this needs pagination with backend filtering
      return _.chain(this.projects)
        .omit(this.assignments_employee)
        .filter({ attributes: { status: Project.STATUS_ENABLED, skip_customer: false } })
        .filter((project) => {
          if (this.search_project) {
            const project_name = project.attributes.name.toLowerCase();
            const search_query = this.search_project.toLowerCase();
            return project_name.includes(search_query);
          }
          return true;
        })
        .orderBy(['attributes.name'], ['asc'])
        .value();
    },

    projects_template() {
      // TODO: this needs pagination with backend filtering
      return _.chain(this.projects)
        .filter({ attributes: { skip_customer: true } })
        .filter((project) => {
          if (this.search_project) {
            const project_name = project.attributes.name.toLowerCase();
            const search_query = this.search_project.toLowerCase();
            return project_name.includes(search_query);
          }
          return true;
        })
        .orderBy(['attributes.name'], ['asc'])
        .value();
    }
  },

  watch: {
    assignments() {
      this.assignments_employee = this.filterAssignments();
    },

    search_project: {
      handler() {
        if (!this.search_project) return;
        this.toggle_projects_other = _.size(this.projects_other) > 0;
        this.toggle_projects_follow = _.size(this.projects_follow) > 0;
        this.toggle_projects_template = _.size(this.projects_template) > 0;
      },
    },
  },

  async created() {
    await this.FETCH_ASSIGNMENTS({
      assigned_type: LEVEL_TYPE.PROJECT,
      assignee_type: this.current_profile.type,
      assignee_id: this.current_profile.id,
      assignment_type: Assignment.TYPE_FOLLOW,
    });
    this.assignments_employee = this.filterAssignments();
  },

  methods: {
    ...mapActions(['FETCH_ASSIGNMENTS', 'CREATE_ASSIGNMENT', 'DELETE_ASSIGNMENT', 'FETCH_PROJECTS']),

    async goToProjectCreate() {
      if (this.$flipper.enabled(this.$FT.TEMPLATE_PROJECTS) && !this.loaded_project_templates) {
        await this.FETCH_PROJECTS({ skip_customer: true });
        this.loaded_project_templates = true;
      }
      this.$emit('closeProjectSidebar');
      this.$buefy.modal.open({
        component: TheProjectModal,
      })
    },

    toggleProject(project_id) {
      this.toggled_project_id = project_id;
    },

    goToOverview() {
      this.$emit('closeProjectSidebar');
      this.$router.push({ name: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_TASKS });
    },

    async toggleOtherProjects() {
      if (!this.loaded_other_projects) {
        await this.FETCH_PROJECTS({ skip_customer: false, followed: false });
        this.loaded_other_projects = true;
      }
      this.toggle_projects_other = !this.toggle_projects_other
    },

    async toggleProjectTemplates() {
      if (!this.loaded_project_templates) {
        await this.FETCH_PROJECTS({ skip_customer: true });
        this.loaded_project_templates = true;
      }
      this.toggle_projects_template = !this.toggle_projects_template
    },

    filterAssignments() {
      return _.filter(
        this.assignments,
        {
          attributes: {
            assigned_type: LEVEL_TYPE.PROJECT,
            assignee_id: this.current_profile.id,
            assignee_type: this.current_profile.type,
            assignment_type: Assignment.TYPE_FOLLOW,
          },
        },
      ).map((e) => e.attributes.assigned_id);
    },

  }
}
</script>
