/* eslint-disable no-shadow */
import api from '@/api';

const URL_FEATURE_TOGGLES = '/app/v1/shared/feature_toggles';

const state = {
  feature_toggles: {}
};

const actions = {
  FETCH_FEATURE_TOGGLES: async ({ commit }, { client_id }) => {
    const r = await api.get(URL_FEATURE_TOGGLES, { params: { client_id } });
    const json = _.get(r, 'data.data', {})

    commit('SET_FEATURE_TOGGLES', json)
  },

  SET_FEATURE_TOGGLES: ({ commit }, { feature_toggles }) => {
    commit('SET_FEATURE_TOGGLES', feature_toggles);
  },
};

const mutations = {
  SET_FEATURE_TOGGLES: (state, feature_toggles) => {
    Object.keys(feature_toggles).forEach((actorType) => {
      state.feature_toggles[actorType] = feature_toggles[actorType]
    })
  },
};

const getters = {
  feature_toggles: (state) => state.feature_toggles,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
