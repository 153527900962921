<template>
  <div>
    <div
      v-if="is_selected"
      class="has-background-primary"
      :style="style">
      <FontAwesomeIcon class="has-text-primary-light" icon="check" />
    </div>
    <img
      v-else-if="show_image"
      alt="avatar"
      :src="url"
      :style="style"
      @error="error = true">
    <!--   <auto-rotate v-else-if="show_image">
    </auto-rotate> -->
    <div
      v-else
      class="ziggu-avatar"
      :style="style">
      <FontAwesomeIcon v-if="icon_fontawesome" :icon="icon_fontawesome" size="lg"/>
      <span v-else>{{ user_initial }}</span>
    </div>
  </div>
</template>

<script>

import { Tenant } from '@/app/data/model_constants';

export const state_to_fontawesome_icon = Object.freeze({
  inbound_email: 'envelope',
  inbound_point: ['far', 'house-chimney-heart'],
  removed_user: 'user',

});

export default {
  name: 'Avatar',

  props: {
    cache: { type: Boolean, default: true },
    username: { type: String, default: '' },
    src: { type: String, default: null },
    size: { type: String, required: true },
    format: {
      type: String,
      default: Tenant.LOGO_FORMAT_ROUND,
    },
    is_selected: { type: Boolean },
    state: {
      type: String,
      default: null,
      validator: (prop) => Object.keys(state_to_fontawesome_icon).includes(prop),
    },
  },

  data() {
    return {
      error: false,
      icon_fontawesome: null,
    };
  },

  computed: {
    show_image() {
      return !!this.src && !this.error;
    },
    user_initial() {
      if (this.username.length > 0) {
        const parts = this.username.split(/[ -]/);
        let initials = '';
        parts.forEach((part) => { initials += part.charAt(0); });
        if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
          initials = initials.replace(/[a-z]+/g, '');
        }
        if (initials[2] === '&') {
          // in case of multiple customers (> 2) we just strip first 2 customers' initials
          return `${initials[0]}&${initials[1]}`;
        }
        return initials.substr(0, 3).toUpperCase();
      }
      return '';
    },

    style() {
      const style = {
        height: `${this.size}px`,
        width: `${this.size}px`,
        display: 'flex',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        objectFit: 'cover',
        fontSize: `${Math.floor(this.size / 2.5)}px`,
      };
      if (this.show_image) {
        style.backgroundColor = 'white';
      }
      if (this.format === Tenant.LOGO_FORMAT_SQUARE) {
        style.borderRadius = '5%';
      }
      if (this.is_selected) {
        style.height = `${Math.floor(this.size) + 2}px`;
        style.width = `${Math.floor(this.size) + 2}px`;
      }
      return style;
    },

    url() {
      if (_.startsWith(this.src, 'blob')) return this.src;
      if (this.cache) return this.src;

      const url = new URL(this.src);
      const bust = Math.round((new Date()).getTime() / 1000);
      url.searchParams.append('_t', bust);

      return url.href;
    },
  },

  created() {
    this.icon_fontawesome = state_to_fontawesome_icon[this.state];
  },
};
</script>
