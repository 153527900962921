<template>
  <div>
    <div v-if="question_type === SurveyQuestion.NPS"
         class="level is-mobile mb-2">
      <div class="level-left">
        <div class="level-item">
          {{ $t('survey.labels.notAtAllLikely') }}
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          {{ $t('survey.labels.extremelyLikely') }}
        </div>
      </div>
    </div>
    <div class="field is-grouped is-grouped-centered"
         data-test="survey-rating">
      <div v-for="(score, index) in scores" :key="score.value"
           class="button"
           :class="{ 'is-primary': score.active,
                     'is-outlined is-primary': !score.active }"
           data-test="button-survey-rating"
           style="height: 40px; width: 100%; "
           :style="[disabled ? { 'cursor': 'default' } : {},
                    index === (scores.length-1) ? { 'margin-right': '0' } : { 'margin-right': '0.3rem' }]"
           @click="!disabled && selectScore(score.value)"
           @mouseleave="!disabled && mouseLeave()"
           @mouseover="!disabled && mouseOver(score.value)">
        <div>
          {{ score.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SurveyQuestion } from '@/app/data/model_constants';

const SCORES_DEFAULT = [
  { active: false, value: 0 },
  { active: false, value: 1 },
  { active: false, value: 2 },
  { active: false, value: 3 },
  { active: false, value: 4 },
  { active: false, value: 5 },
  { active: false, value: 6 },
  { active: false, value: 7 },
  { active: false, value: 8 },
  { active: false, value: 9 },
  { active: false, value: 10 },
];

export default {
  name: 'Rating',
  props: {
    disabled: { type: Boolean, default: false },
    default_value: { type: Number, default: null },
    question_type: { type: String, default: null },
  },

  data() {
    const scores = _.cloneDeep(SCORES_DEFAULT);

    if ((typeof this.default_value) === 'number') {
      scores.forEach((score, index) => {
        scores[index].active = score.value <= this.default_value;
      });
    }

    return {
      scores,
      selected: false,
      SurveyQuestion,
    };
  },

  watch: {
    default_value() {
      this.scores = _.cloneDeep(SCORES_DEFAULT);
      this.configureScore(this.default_value);
      this.selected = this.default_value >= 0 && this.default_value <= 10;
    },
  },

  methods: {
    mouseOver(scoreValue) {
      if (this.selected) return;
      this.configureScore(scoreValue);
    },

    mouseLeave() {
      if (this.selected) return;
      this.scores.forEach((score, index) => {
        this.scores[index].active = false;
      });
    },

    selectScore(scoreValue) {
      this.configureScore(scoreValue);
      this.selected = true;
      this.$emit('scoreSelected', scoreValue);
    },

    configureScore(scoreValue) {
      if (_.isNil(scoreValue)) return;

      this.scores.forEach((score, index) => {
        this.scores[index].active = score.value <= scoreValue;
      });
    },
  },
};
</script>
